import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

export const useGetPartnersSectionPermissions = () => {
  const isAdmin = useSelector((state: State) => state.society?.role?.isAdmin);
  const adminPermissions = useSelector(
    (state: State) => state.society.permissions
  );
  const isDemo = useSelector((state: State) => state.society?.role?.isDemo);

  const isReadOnly = isDemo || adminPermissions?.partners?.readOnly;

  return {
    data: { isAdmin, isReadOnly },
  };
};
