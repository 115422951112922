/* eslint-disable array-callback-return */
import { setModal } from 'modules/_shared/redux/modalActions';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';

import AddSocietyDirector from 'modules/_shared/components/Modals/AddSocietyDirector';
import NoItemsAvailable from 'modules/_shared/components/Modals/NoItemsAvailable';

import { useTranslate } from 'hooks/useTranslate';
import directorTypes from './directorTypes';
import SocietyDirectorsRow from './SocietyDirectorsRow';
import statusTypes from './statusTypes';

function SocietyDirectors({ society, isAdmin, setPage, userId }) {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [directors, setDirectors] = useState([]);
  const [seeNotCurrent, setSeeNotCurrent] = useState(false);

  function changeView() {
    setSeeNotCurrent(!seeNotCurrent);
  }
  const getStatus = (director, currStatusTypes) => {
    if (!director?.directorStatus) {
      if (!director?.endDate || new Date(director?.endDate) - new Date() > 0)
        return currStatusTypes.var.ACTIVE;
      return currStatusTypes.var.INACTIVE;
    }
    return director?.directorStatus;
  };

  useEffect(() => {
    dispatch(getDocuments({ society: society['_id'] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  useEffect(() => {
    const currStatusTypes = statusTypes(i18n);
    const currDirectorTypes = directorTypes(i18n);
    if (society) {
      const filteredDirectors = society?.directors?.filter((director) => {
        if (
          seeNotCurrent ||
          (!seeNotCurrent &&
            getStatus(director, currStatusTypes) === currStatusTypes.var.ACTIVE)
        ) {
          return director;
        }
      });

      setDirectors(
        filteredDirectors.map((director) => {
          const partner = society?.partners?.find(
            (partner) => partner?.cif === director?.cif
          );
          const directorImage = partner ? partner?.image : null;

          return (
            <SocietyDirectorsRow
              key={director['_id']}
              userId={userId}
              directorId={director['_id']}
              societyId={society['_id']}
              socialDenomination={director.socialDenomination}
              nationality={director.nationality}
              initDate={director.initDate}
              endDate={director.endDate}
              birthDate={director.birthDate}
              cif={director.cif}
              address={director.address}
              email={director.email}
              image={directorImage}
              directorType={director.directorType}
              directorStatus={director.directorStatus}
              documents={director.documents}
              currStatusTypes={currStatusTypes}
              currDirectorTypes={currDirectorTypes}
              isNaturalPerson={director?.isNaturalPerson}
              representative={director?.representative}
            />
          );
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society, seeNotCurrent, i18n.language]);

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title">
            {t('CompanyAdministratorBody')}
          </h5>
          <div className="nk-block-des">
            <p>{t('TheseAreThePeopleOrCompan')}</p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        {isAdmin ? (
          <div className="nk-block-head">
            <div
              className="nk-block-head-content"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  store.dispatch(
                    setModal(
                      <AddSocietyDirector
                        societyId={society['_id']}
                        action="ADD"
                        userId={userId}
                      />
                    )
                  );
                  setPage(1);
                }}
                disabled={isDemo}
              >
                {t('AddMember')}
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => changeView()}
                >
                  <em
                    className={
                      !seeNotCurrent ? 'icon ni ni-eye' : 'icon ni ni-eye-off'
                    }
                  />
                  <span>
                    {!seeNotCurrent ? t('SeeNotCurrent') : t('HideNotCurrent')}
                  </span>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="card card-bordered card-preview">
          <div
            className="table-responsive overflow-hidden"
            id="society__directors-table"
          >
            <table className="nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
                  <th className="nk-tb-col">
                    <span>{t('NameAndSurnameSociety')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('Nationality')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('StartDate')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('EndDate')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('E-Mail')}</span>
                  </th>
                  <th className="nk-tb-col">
                    <span>{t('Position')}</span>
                  </th>
                  <th className="nk-tb-col">
                    <span>{t('Status')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl text-center">
                    <span>{t('Doc')}</span>
                  </th>
                  {!isDemo && (
                    <th className="nk-tb-col">
                      <span />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {directors?.length ? (
                  directors
                ) : (
                  <NoItemsAvailable mainMessage={t('NoMembersAvailable')} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocietyDirectors;
