import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { User } from 'types';

import { setModal } from 'modules/_shared/redux/modalActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import { updateUser } from 'modules/profile/redux/userActions';
import { store } from 'redux/store';
import successSwal from 'utils/successSwal';
import { useGetAdminPermissions } from '../hooks/useGetAdminPermissions';

import EditPermissionsModalHeader from './EditPermissionsModalHeader';
import EditPermissionsModalFooter from './EditPermissionsModalFooter';
import EditPermissionsModalBody from './EditPermissionsModalBody';

type Props = {
  administrator: User;
  societyId: string;
};

const EditPermissionsModal: FC<Props> = ({ administrator, societyId }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user);

  const administratorPermissionsResult = useGetAdminPermissions({
    administrator,
    societyId,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { administratorPermissions } = administratorPermissionsResult.data;
  const { setAdministratorPermissions } =
    administratorPermissionsResult.actions;

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleChangePermissions = (permissions: any) => {
    setAdministratorPermissions(permissions);
  };

  const handleSavePermissions = async () => {
    try {
      setIsLoading(true);
      const adminPermissions: {
        [key: string]: { enabled: boolean; readOnly: boolean };
      } = {};

      administratorPermissions.forEach((permission) => {
        adminPermissions[permission['name']] = {
          enabled: permission.checked,
          readOnly: permission.readOnly,
        };
      });

      await store.dispatch(
        updateUser(
          administrator['_id'],
          {
            society: societyId,
            permissions: adminPermissions,
          },
          user['_id'],
          false
        )
      );

      successSwal(t('PermissionsUpdatedSuccessfully'));

      handleCloseModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <CustomLoading />}

      <EditPermissionsModalHeader handleCloseModal={handleCloseModal} />

      <EditPermissionsModalBody
        administratorPermissions={administratorPermissions}
        handleChangePermissions={handleChangePermissions}
      />

      <EditPermissionsModalFooter
        handleSavePermissions={handleSavePermissions}
      />
    </>
  );
};

export default EditPermissionsModal;
