/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import userTypes from 'constants/userTypes';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import EditModal from 'modules/profile/components/UserMyData/EditModal';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { store } from 'redux/store';
import { PartnerRepresentant, Society } from 'types';
import { getFullAddress } from 'utils/filters';

type PartnerRepresentantPersonalProps = {
  society: Society;
  partnerRepresentant: PartnerRepresentant;
};

const PartnerRepresentantPersonal: FC<PartnerRepresentantPersonalProps> = ({
  society,
  partnerRepresentant,
}) => {
  const { t } = useTranslate();

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <div className="nk-block float-right" />
        </div>
        <h5 className="title">{t('PersonalInformation')}</h5>
        <p>{t('BasicPersonalInformation')}</p>
        <div className="nk-block-head nk-block-head-line" />

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('FullName')}</span>
          <span className="profile-ud-value text-left">
            {partnerRepresentant.name || ''}
          </span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Nif')}</span>
          <span className="profile-ud-value text-left">
            {partnerRepresentant?.cif || '-'}
          </span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Telephone')}</span>
          <span className="profile-ud-value text-left">
            {partnerRepresentant?.phone || '-'}
          </span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Address')}</span>
          <span className="profile-ud-value text-left">
            {getFullAddress(partnerRepresentant.address) || '-'}
          </span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Email')}</span>
          <span className="profile-ud-value text-left">
            {partnerRepresentant?.email || '-'}
          </span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">
            {t('ContributionDate')}
          </span>
          <span className="profile-ud-value text-left">
            {format(new Date(partnerRepresentant?.createdAt), 'dd/MM/yyyy')}
          </span>
        </div>

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('IsPrivate')}</span>
          <span className="profile-ud-value text-left">
            {partnerRepresentant?.isPrivate ? t('Yes') : t('Not')}
          </span>
        </div>
      </div>

      <div className="nk-block" />
      <div className="nk-divider divider md" />
    </div>
  );
};

export default PartnerRepresentantPersonal;
