/* eslint-disable react/jsx-props-no-spreading */

import NumberFormat from 'react-number-format';

import { numberFormat } from 'constants/formats';
import operationTypes from 'constants/operationTypes';

import { titleColumn } from '../constants/partnerBookConstants';
import createPartnersArray from '../utils/createPartnersArray';

type FilterValueProps = {
  partnerIndex: number;
  index: number;
  data: any;
  column: string;
  isForScreen: boolean;
  bookPartners: any;
  bookOperations: any;
};

const filterValue = ({
  partnerIndex,
  index,
  data,
  column,
  isForScreen,
  bookPartners,
  bookOperations,
}: FilterValueProps) => {
  console.log('🚀 cclog ~ column:', column, data);
  const bookPartnersArray = createPartnersArray(bookPartners);

  const condition1 =
    bookOperations[index - 2] === operationTypes.SELL_PARTICIPATION ||
    bookOperations[index - 2] === operationTypes.HERITAGE ||
    bookOperations[index - 2] === operationTypes.DONATION;

  const condition2 =
    bookOperations[index - 3] === operationTypes.SELL_PARTICIPATION ||
    bookOperations[index - 3] === operationTypes.HERITAGE ||
    bookOperations[index - 3] === operationTypes.DONATION;

  if (data && column === titleColumn.PARTNERS) {
    return data.toString();
  }

  if (data !== 0 && column === titleColumn.PERCENT) {
    if (data === null) return '';

    return isForScreen
      ? `${parseFloat(data)?.toFixed(2)}%`
      : parseFloat(data?.toFixed(2));
  }

  if (data !== 0 && column !== titleColumn.PERCENT) {
    if (data === null) return '';

    return isForScreen ? (
      <NumberFormat displayType="text" value={data} {...numberFormat} />
    ) : (
      data
    );
  }

  if (column === titleColumn.TOTAL) {
    if (
      bookOperations[index - 1] === operationTypes.CAPITAL_DECREASE &&
      bookPartnersArray[partnerIndex][index - 1]
    )
      return 0;
    if (condition1 && bookPartnersArray[partnerIndex][index - 1]) return 0;
  }

  if (column === titleColumn.PERCENT) {
    if (
      bookOperations[index - 2] === operationTypes.CAPITAL_DECREASE &&
      bookPartnersArray[partnerIndex][index - 2]
    )
      return isForScreen ? '0.00%' : 0.0;
    if (condition2 && bookPartnersArray[partnerIndex][index - 2])
      return isForScreen ? '0.00%' : 0.0;
  }

  return '';
};

export default filterValue;
