/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import { getTenderOffers } from 'modules/transactions/redux/tenderOfferActions';

import consolidationOptions from 'constants/consolidationOptions';
import menuTypes from 'constants/menuTypes';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import sizes from 'constants/sizes';

import hasPlanCustomDilution from 'utils/customDilution';
import {
  getActualSociety,
  getPlanPool,
  getSoldUnitsByPlan,
  getUsedSharesFromPlan,
} from 'utils/filters';
import { formatNumber } from 'utils/formats';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import PlanInvitation from 'modules/_shared/components/Modals/PlanInvitation';
import transformData from 'modules/_shared/components/Tables/helpers';
import BeneficiaryPlanRow from 'modules/beneficiaries/components/BeneficiaryPlanRow';
import { useGetPermissions } from 'modules/beneficiaries/hooks/useGetPermissions';

import { useTranslate } from 'hooks/useTranslate';
import { store } from 'redux/store';
import { setModal } from 'modules/_shared/redux/modalActions';
import menuOptions from './utils/menuOptions';
import planOptions from './utils/planOptions';
import tableColumns from './utils/tableColumns';

import BeneficiaryPlanHeader from './components/BeneficiaryPlanHeader';
import BeneficiaryPlanMetricsCard from './components/BeneficiaryPlanMetricsCard';
import BeneficiaryPlanProgressCard from './components/BeneficiaryPlanProgressCard';
import BeneficiaryPlanTable from './components/BeneficiaryPlanTable';

function BeneficiaryPlanList() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslate();
  const { societyId } = useParams();

  const user = useSelector((state) => state?.user);
  const plans = useSelector((state) => state?.plans);
  const drafts = useSelector((state) => state?.drafts);
  const tenderOffers = useSelector((state) => state?.tenderOffers);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);

  const { isReadOnly } = useGetPermissions().data;

  const [tableData, setTableData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );
  const [currentPlanOptions, setCurrentPlanOptions] = useState(
    planOptions(i18n)
  );
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );
  const [
    percentageIncentivePlansCaptable,
    setPercentageIncentivePlansCaptable,
  ] = useState(0);

  const [rows, setRows] = useState([]);
  const [summaryRow, setSummaryRow] = useState({
    assignedShares: 0,
    sharesTotal: 0,
  });
  const [summaryTenderOffers, setSummaryTenderOffers] = useState([]);

  const [pendingInvitations, setPendingInvitations] = useState([]);

  const filterOptions = (plan) => {
    let options = currentPlanOptions;

    if (summaryTenderOffers[plan._id] === 0) {
      options = currentPlanOptions.map((option) => {
        if (option.key === 'UPDATE_UNITS') {
          return {
            ...option,
            disabled: true,
          };
        }
        return option;
      });
    }

    if (plan.hasInvitation && plan.invitationModel) {
      options = [
        ...options,
        {
          icon: 'ni-edit',
          text: t('EditInvitationLetter'),
          action: () => {
            store.dispatch(
              setModal(
                <PlanInvitation
                  plan={plan}
                  society={actualSociety}
                  size={sizes.XXL}
                  action="EDIT"
                  invitationModel={plan.invitationModel}
                />
              )
            );
          },
          dontShowCurrent: () => isReadOnly,
        },
      ];
      return options;
    }
    return options;
  };

  useEffect(() => {
    const beneficiariesPending = actualSociety?.beneficiaries
      ?.filter(
        (beneficiary) =>
          beneficiary.isDraft === false &&
          beneficiary?.invitationStatus === 'PENDING'
      )
      .map((beneficiary) => ({
        ...beneficiary,
        planName: plans.find((plan) => plan['_id'] === beneficiary.plan)?.name,
      }));

    setPendingInvitations(beneficiariesPending);
  }, [actualSociety, plans]);

  useEffect(() => {
    if (!tenderOffers || !plans || !actualSociety) return;

    const soldUnitsByPlan = {};

    plans?.forEach((plan) => {
      soldUnitsByPlan[plan._id] = getSoldUnitsByPlan(
        tenderOffers,
        actualSociety,
        plan._id,
        false
      );
    });

    setSummaryTenderOffers(soldUnitsByPlan);
  }, [tenderOffers, plans, actualSociety]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n));
    setCurrentPlanOptions(planOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (!societyId) return;

    dispatch(getStockPlans(societyId));
    dispatch(getTenderOffers(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [user, societyId, actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety && plans.length && tenderOffers) {
      const actualSocietyPlans = plans.filter(
        (plan) => plan.society === societyId
      );

      const newData = actualSocietyPlans.map((plan, index) => ({
        index: index + 1,
        initials:
          plan.planType === 'STOCK_OPTIONS'
            ? 'SO'
            : plan.planType === 'WARRANT'
            ? 'W'
            : 'PS',
        name: plan.name,
        planName: plan.name,
        draft: plan.isDraft,
        startDate: plan.startDate,
        sharePrice: plan.sharePrice,
        cliff: plan.cliff,
        vestingPeriod: plan.vestingPeriod,
        consolidation:
          consolidationOptions.find((c) => c.value === plan.consolidation)
            ?.text || '',
        pool: getPlanPool(plan, actualSociety),
        planType: operationTypesRealNames[plan.planType],
        shares: `${formatNumber(
          getUsedSharesFromPlan(plan, actualSociety, tenderOffers)
        )}/${formatNumber(plan.sharesTotal)}`,
        soldUnits: getSoldUnitsByPlan(
          tenderOffers,
          actualSociety,
          plan._id,
          false
        ),
        id: plan['_id'],
        user,
        society: actualSociety,
        menuOptions: filterOptions(plan),
        hasOption: true,
        currentPlan: plan,
        isReadOnly,
      }));

      setTableData(
        transformData({
          data: newData,
          columns: currentTableColumns,
        })
      );

      setSummaryRow(
        actualSocietyPlans
          .filter((plan) => !plan.isDraft)
          .reduce(
            (acc, plan) => ({
              assignedShares:
                acc.assignedShares +
                getUsedSharesFromPlan(plan, actualSociety, tenderOffers),
              sharesTotal: acc.sharesTotal + plan?.sharesTotal || 0,
            }),
            { assignedShares: 0, sharesTotal: 0 }
          )
      );
    }
  }, [plans, actualSociety, societyId, rows, tenderOffers]);

  useEffect(() => {
    if (actualSociety && plans.length) {
      const actualSocietyPlans = plans.filter(
        (plan) => plan.society === societyId
      );
      setRows(
        actualSocietyPlans.map((plan, index) => (
          <BeneficiaryPlanRow
            key={plan['_id']}
            index={index + 1}
            society={actualSociety}
            plan={plan}
            tenderOffers={tenderOffers}
          />
        ))
      );
    }
  }, [plans, actualSociety, societyId, i18n.language, tenderOffers]);

  useEffect(() => {
    setPercentageIncentivePlansCaptable(
      plans.reduce(
        (acc, plan) =>
          !plan.isDraft ? acc + getPlanPool(plan, actualSociety) : acc,
        0
      )
    );
  }, [actualSociety, drafts, plans]);

  useEffect(() => {
    setCurrentMenuOptions(
      menuOptions(i18n).map((option, index) => ({
        ...option,
        disabled: index === 0 && hasPlanCustomDilution(plans),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, plans]);

  return actualSociety ? (
    <>
      <div className="nk-content-body" style={{}}>
        <div className="nk-content-wrap">
          <BeneficiaryPlanHeader rows={rows} menuOptions={currentMenuOptions} />

          {rows.length > 0 && (
            <>
              <div className="nk-block d-flex flex-column flex-lg-row justify-content-around mx-0 mx-lg-2">
                <BeneficiaryPlanMetricsCard
                  data={summaryRow}
                  percent={percentageIncentivePlansCaptable}
                  invitations={pendingInvitations}
                />

                <BeneficiaryPlanProgressCard data={summaryRow} />
              </div>

              <BeneficiaryPlanTable
                data={tableData}
                columns={currentTableColumns}
              />
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default BeneficiaryPlanList;
