/* eslint-disable no-underscore-dangle */
import { store } from 'redux/store';
import { useTranslate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import sendEmail from 'modules/communications/redux/mailActions';
import { updateUser } from 'modules/profile/redux/userActions';
import { updateSociety } from 'modules/society/redux/societyActions';

import 'modules/_shared/components/Modals/Modals.scss';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import societyAdministratorPermissions from '../constants/societyAdministratorPermissions';
import { createAdminEmail } from '../utils';

import AddAdminModalHeader from './AddAdminModalHeader';
import AddAdminModalFooter from './AddAdminModalFooter';
import AddAdminModalBody from './AddAdminModalBody';

function AddAdminModal() {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [email, setEmail] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const sendNotificationEmail = () => {
    const { language } = i18n;
    const emailData = createAdminEmail({
      t,
      language,
      user,
      email,
      actualSociety,
      action: 'ADD',
    });

    dispatch(sendEmail(emailData));
  };

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleSaveAdministrator = async () => {
    try {
      setIsLoading(true);
      const adminPermissions = {};

      selectedPermissions.forEach((permission) => {
        adminPermissions[permission['name']] = {
          enabled: permission.checked,
          readOnly: permission.readOnly,
        };
      });

      await store.dispatch(
        updateSociety(
          actualSociety['_id'],
          {
            admin: {
              add: email,
              createdBy: user['_id'],
            },
          },
          false
        )
      );

      await store.dispatch(
        updateUser(
          null,
          {
            admin: { add: email },
            society: actualSociety['_id'],
            permissions: adminPermissions,
          },
          user['_id'],
          false
        )
      );

      sendNotificationEmail();
      dispatch(setModal(null));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePermissions = (permissions) => {
    setSelectedPermissions(permissions);
  };

  return (
    <>
      {isLoading && <CustomLoading />}

      <AddAdminModalHeader handleCloseModal={handleCloseModal} />

      <AddAdminModalBody
        email={email}
        societyAdministratorPermissions={societyAdministratorPermissions}
        handleChangeEmail={handleChangeEmail}
        handleChangePermissions={handleChangePermissions}
      />

      <AddAdminModalFooter handleSaveAdministrator={handleSaveAdministrator} />
    </>
  );
}

export default AddAdminModal;
