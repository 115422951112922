/* eslint-disable no-alert */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatOldTemplates } from 'modules/_shared/components/EditorWYSIWYG/helpers';

import EditorWYSIWYG from 'modules/_shared/components/EditorWYSIWYG';
import {
  getDocuments,
  updateDocument,
} from 'modules/documents/redux/documentActions';
import {
  getStockPlans,
  updateStockPlan,
} from 'modules/beneficiaries/redux/planActions';
import Logo from './account.svg';

const statusTypes = {
  CONVERTED: <span className="badge badge-success">Converted</span>,
  PENDING: <span className="badge badge-info">Pending</span>,
  NA: <span className="badge badge-secondary">N/A</span>,
  IN_PROCESS: <span className="badge badge-warning">In process</span>,
  SKIPPED: <span className="badge badge-danger">Skipped</span>,
};

const AdminCenter = () => {
  const dispatch = useDispatch();
  const { society, documents, plans } = useSelector((state) => ({
    society: state?.society?.actualSociety,
    documents: state?.documents,
    plans: state?.plans,
  }));

  const [showDocuments, setShowDocuments] = useState(false);
  const [showPlans, setShowPlans] = useState(true);

  const [editorContent, setEditorContent] = useState('');
  const [currentDocuments, setCurrentDocuments] = useState([]);
  const [currentPlans, setCurrentPlans] = useState([]);

  const convertOldTemplates = (template) => {
    const templateConverted = formatOldTemplates(template);
    setEditorContent(templateConverted.html);
  };

  const setStatusForDocument = (index, status) => {
    const updatedDocuments = [...currentDocuments];
    updatedDocuments[index].status = status;
    setCurrentDocuments(updatedDocuments);
  };

  const setStatusForPlan = (index, status) => {
    const updatedPlans = [...currentPlans];
    updatedPlans[index].status = status;
    setCurrentPlans(updatedPlans);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleOpenPlanInEditor = (plan) => {
    if (!plan.hasInvitation) {
      alert('This plan has no invitation');
      return;
    }

    if (!plan?.invitationModel?.html) {
      alert('This plan has no invitation model');
      return;
    }

    if (plan.invitationModel.html) {
      setEditorContent(plan.invitationModel.html);
    }
  };

  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      // eslint-disable-next-line no-await-in-loop
      await callback(array[index], index, array);
    }
  };

  const formatIndividual = async (dataType, index) => {
    try {
      if (dataType === 'DOCUMENTS') {
        setStatusForDocument(index, 'IN_PROCESS');
        await delay(500);

        const document = currentDocuments[index];

        const templateConverted = formatOldTemplates(document.editorModel);
        const newDocument = {
          ...document,
          editorModel: templateConverted.html,
        };
        setEditorContent(templateConverted.html);

        await dispatch(updateDocument(newDocument));

        setStatusForDocument(index, 'CONVERTED');
        await delay(500);
      }

      if (dataType === 'PLANS') {
        setStatusForPlan(index, 'IN_PROCESS');
        await delay(500);

        const plan = currentPlans[index];

        const templateConverted = formatOldTemplates(plan.invitationModel);
        const newPlan = {
          ...plan,
          invitationModel: { html: templateConverted.html },
        };
        setEditorContent(templateConverted.html);

        await dispatch(
          updateStockPlan(plan._id, {
            ...newPlan,
          })
        );

        setStatusForPlan(index, 'CONVERTED');
        await delay(500);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatTemplates = async (dataType) => {
    let currentData;
    let processedData;

    if (dataType === 'DOCUMENTS') {
      currentData = currentDocuments;
      processedData = async (document, index) => {
        try {
          setStatusForDocument(index, 'IN_PROCESS');
          await delay(500);

          if (document.status !== 'CONVERTED') {
            const templateConverted = formatOldTemplates(document.editorModel);
            const newDocument = {
              ...document,
              editorModel: templateConverted.html,
            };
            setEditorContent(templateConverted.html);

            await dispatch(updateDocument(newDocument));
          }

          setStatusForDocument(index, 'CONVERTED');
          await delay(500);
        } catch (error) {
          console.error(error);
        }
      };
    }

    if (dataType === 'PLANS') {
      currentData = currentPlans;
      processedData = async (plan, index) => {
        try {
          setStatusForPlan(index, 'IN_PROCESS');
          if (plan.status === 'CONVERTED' || plan.status !== 'NA') {
            setStatusForPlan(index, 'SKIPPED');
          }
          await delay(500);

          if (plan.status !== 'CONVERTED' && plan.status !== 'NA') {
            const templateConverted = formatOldTemplates(plan.invitationModel);
            const newPlan = {
              ...plan,
              invitationModel: { html: templateConverted.html },
            };
            setEditorContent(templateConverted.html);

            await dispatch(
              updateStockPlan(plan._id, {
                ...newPlan,
              })
            );
          }

          setStatusForPlan(index, 'CONVERTED');
          await delay(500);
        } catch (error) {
          console.error(error);
        }
      };
    }

    await asyncForEach(currentData, processedData);
  };

  const TableDocuments = ({ data }) => (
    <table className="table nk-tb-list nk-tb-ulist cursor-pointer">
      <thead>
        <tr className="nk-tb-item nk-tb-head">
          <th className="nk-tb-col">
            <span className="sub-text">Nr</span>
          </th>
          <th className="nk-tb-col">
            <span className="sub-text">Category</span>
          </th>
          <th className="nk-tb-col">
            <span className="sub-text">Document</span>
          </th>
          <th className="nk-tb-col">
            <span className="sub-text">Status</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((document, index) => (
          <tr
            className="nk-tb-item cursor-pointer"
            id={document._id}
            onClick={() => setEditorContent(document.editorModel)}
          >
            <td className="nk-tb-col">
              <span className="tb-lead">{index + 1}</span>
            </td>
            <td className="nk-tb-col">
              <span className="tb-lead">{document.category}</span>
            </td>
            <td className="nk-tb-col">
              <span className="tb-lead">{document.name}</span>
            </td>
            <td className="nk-tb-col">
              <span className="tb-lead">{statusTypes[document.status]}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const TablePlans = ({ data }) => (
    <table className="table nk-tb-list nk-tb-ulist">
      <thead>
        <tr className="nk-tb-item nk-tb-head">
          <th className="nk-tb-col">
            <span className="sub-text">Nr</span>
          </th>
          <th className="nk-tb-col">
            <span className="sub-text">ID</span>
          </th>
          <th className="nk-tb-col">
            <span className="sub-text">Plan</span>
          </th>
          <th className="nk-tb-col">
            <span className="sub-text">Society</span>
          </th>
          <th className="nk-tb-col">
            <span className="sub-text">Actions</span>
          </th>
          <th className="nk-tb-col">
            <span className="sub-text">Status</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((plan, index) => (
          <tr className="nk-tb-item" id={plan._id}>
            <td className="nk-tb-col">
              <span className="tb-lead">{index + 1}</span>
            </td>
            <td className="nk-tb-col">
              <span className="tb-lead">{plan._id}</span>
            </td>
            <td className="nk-tb-col">
              <span className="tb-lead">{plan.name}</span>
            </td>
            <td className="nk-tb-col">
              <span className="tb-lead">{plan.society}</span>
            </td>
            <td className="nk-tb-col">
              <div className="d-flex justify-content-between">
                {plan.status === 'PENDING' && (
                  <button
                    type="button"
                    className="btn btn-primary px-1 mr-1 py-0"
                    onClick={() => formatIndividual('PLANS', index)}
                    disabled={
                      plan.status === 'CONVERTED' ||
                      plan.status === 'NA' ||
                      !plan.invitationModel
                    }
                  >
                    <em className="icon ni ni-update" />
                  </button>
                )}

                {plan.status === 'CONVERTED' && (
                  <button
                    type="button"
                    className="btn btn-success px-1 ml-1 py-0"
                    onClick={() => handleOpenPlanInEditor(plan)}
                    disabled={plan.status === 'NA' || plan.status === 'PENDING'}
                  >
                    <em className="icon ni ni-eye" />
                  </button>
                )}
              </div>
            </td>
            <td className="nk-tb-col">
              <span className="tb-lead">{statusTypes[plan.status]}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const handleShowScript = async () => {
    try {
      dispatch(getDocuments({ society: society._id }));
    } catch (error) {
      console.error(error);
    }
  };

  const getDocumentRowStatus = (document) => {
    if (document?.editorModel && typeof document?.editorModel === 'string') {
      return 'CONVERTED';
    }

    if (document?.editorModel?.blocks || document?.invitationModel) {
      return 'PENDING';
    }

    if (!document?.editorModel?.blocks && !document?.invitationModel) {
      return 'NA';
    }
  };

  const getPlanRowStatus = (plan) => {
    if (!plan?.hasInvitation) {
      return 'NA';
    }
    if (
      plan?.invitationModel?.html &&
      typeof plan?.invitationModel?.html === 'string'
    ) {
      return 'CONVERTED';
    }

    if (plan?.invitationModel?.blocks) {
      return 'PENDING';
    }
  };

  useEffect(() => {
    if (documents?.length) {
      const docs = documents.map((doc) => ({
        ...doc,
        status: getDocumentRowStatus(doc),
      }));
      setCurrentDocuments(docs);
    }
  }, [documents]);

  useEffect(() => {
    if (plans?.length) {
      const plansFormatted = plans.map((plan) => ({
        ...plan,
        status: getPlanRowStatus(plan),
      }));
      setCurrentPlans(plansFormatted);
    }
  }, [plans]);

  useEffect(() => {
    if (!showPlans) return;

    dispatch(getStockPlans());
  }, [showPlans]);

  return (
    <>
      <div className="nk-content-body">
        <h1>
          <img
            src={Logo}
            alt="Logo"
            style={{
              height: '70px',
              marginRight: '20px',
            }}
          />
          4dmin C3nt3r
        </h1>
        <p>Processing data</p>
      </div>
      <div className="nk-content-block mt-4">
        <h3>Script converting Old Templates</h3>

        <EditorWYSIWYG
          editorContent={editorContent}
          setEditorContent={setEditorContent}
        />

        <button
          type="button"
          className="btn btn-primary my-4"
          onClick={handleShowScript}
        >
          Load Data
        </button>
        <button
          type="button"
          className="btn btn-primary my-4 mx-4"
          onClick={() => formatTemplates(showDocuments ? 'DOCUMENTS' : 'PLANS')}
        >
          Format Old Templates
        </button>

        {showDocuments && (
          <>
            <h3>{currentDocuments?.length || 0} documentos</h3>
            <TableDocuments data={currentDocuments} />
          </>
        )}
        {showPlans && (
          <>
            <h3>{currentPlans?.length || 0} planes</h3>
            <TablePlans data={currentPlans} />
          </>
        )}
      </div>
    </>
  );
};

export default AdminCenter;
