/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';

import { useGetPermissions } from 'modules/beneficiaries/hooks/useGetPermissions';
import BeneficiaryCalendarTabRow from './BeneficiaryCalendarTabRow';

function BeneficiaryCalendarTab({ society, beneficiaryAllPlans }) {
  const { t } = useTranslate();

  const { isReadOnly } = useGetPermissions().data;

  const [rows, setRows] = useState(0);

  useEffect(() => {
    if (beneficiaryAllPlans?.length) {
      const rows = beneficiaryAllPlans.map((b, index) => (
        <BeneficiaryCalendarTabRow
          key={b._id}
          index={index + 1}
          planName={b.planData?.name}
          plan={b.planData}
          society={society}
          beneficiary={b}
          unitsGranted={b.sharesCount?.future || 0}
        />
      ));
      setRows(rows);
    }
  }, [beneficiaryAllPlans]);

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <div className="nk-block-between g-3">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">{t('CalendarsTabTitle')}</h4>
            <div className="nk-block-des">
              <p>{t('CalendarsTabSubtitle')}</p>
            </div>
          </div>
        </div>
      </div>

      {rows.length ? (
        <>
          <div className="nk-block nk-block-lg">
            <div className="card card-bordered card-preview">
              <table className="table table-striped" id="calendarsTable">
                <thead>
                  <tr>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('Nr')}
                    </th>
                    <th scope="col">{t('Plan')}</th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('TotalUnitsGranted')}
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('VestedUnits')}
                    </th>
                    {!isReadOnly && <th scope="col">{t('Calendar')}</th>}
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default BeneficiaryCalendarTab;
