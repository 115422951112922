import i18n from 'i18n/config';

export const INITIAL_DATE = '2022-01-01:00:00';
export const OLDEST_DATE = '1970-01-01:00:00';
export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_FORMAT_LONG = 'PPP';
export const DATE_FORMAT_SHORT = 'dd/MM/yy';
export const DATE_FORMAT_MONTH_ONLY = 'MM/yyyy';
export const DATE_FORMAT_WITH_TIME = 'dd/MM/yyyy HH:mm';

export const SELECCIONAR_SOCIO = i18n.t('SelectPartner');
export const ANADIR_SOCIO = i18n.t('AddPartner');
export const SELECT_CATEGORY = i18n.t('SelectCategory');

export const ANADIR_CLASE = i18n.t('AddClass');

export const MAX_FILESIZE_ALLOWED = { text: '50mb', value: 50000000 };
export const TYPE_FILES_ALLOWED = '.docx,.doc,.xls,.xlsx,.ppt,.pptx,.pdf';
export const PDF_TYPE_FILE_ALLOWED = '.pdf';

export const BLANK_PROFILE_PICTURE = '/images/avatar/blank-profile.png';
export const BLANK_COMPANY_LOGO = '/images/avatar/blank-company.png';
export const STTOK_LOGO =
  'https://www.resources.sttok.com/images/logo-dark.png';
export const STTOK_LOGO_2X =
  'https://www.resources.sttok.com/images/logo-dark2x.png';
export const STTOK_EMAIL_DOMAIN = '@sttok.com';
export const STTOK_WEBSITE = 'https://www.sttok.com';
export const STTOK_DEMO_URL = 'https://calendly.com/sttok/30min';

export const NUMERATE_FROM_START = i18n.t('NumerateFromStart');
export const SELECT_OPTION = i18n.t('Select');
export const SELECT_YEAR = i18n.t('SelectAYear');

export const SEARCH_MIN_LENGTH = 3;
export const SEARCH_MAX_LENGTH = 50;
export const DOCUMENTS_ITEMS_PER_PAGE = 10;
export const COMMENTS_MAX_LENGTH = 500;
export const MAX_PARTNERS_CAPTABLE = 20;

export const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // minutes
export const INACTIVITY_MODAL_TIMER = 30 * 1000; // seconds
export const INACTIVITY_VERIFICATION_INTERVAL = 60 * 1000; // seconds

export const EMAIL_PATTERN = '[^@\\s]+@[^@\\s]+\\.[^@\\s]+';
export const GENERATED_EMAIL_PATTERN = /[0-9]{4}@sttok\.com$/i;

export const JSPDF_MARGINS = {
  top: 40,
  bottom: 60,
  left: 40,
  width: 522,
};

export const DAYS_FOR_DISPLAY_VESTING_MESSAGE = 7;

export const DEFAULT_HEADER_LOGO = '/images/logo-dark.png';
export const DEFAULT_HEADER_COLOR = '#ffffff';
export const DEFAULT_HEADER_TEXT_COLOR = '#6e82a5';
export const DEFAULT_HEADER_TEXT_HOVER_COLOR = '#6576ff';
export const DEFAULT_EMAIL_LOGO =
  'https://www.resources.sttok.com/images/logo-dark2x.png';
export const DEFAULT_EMAIL_FRAME_LOGO = '/images/logo-dark.png';
export const DEFAULT_EMAIL_FRAME_COLOR = '#f5f6fa';
export const DEFAULT_EMAIL_HEADINGS_COLOR = '#6576ff';
export const DEFAULT_EMAIL_BODY_TEXT_COLOR = '#8094ae';
export const DEFAULT_EMAIL_LINK_COLOR = '#6576FF';
export const DEFAULT_EMAIL_BUTTON_COLOR = '#6576FF';
export const DEFAULT_EMAIL_BUTTON_TEXT_COLOR = '#ffffff';
export const DEFAULT_LOGO_HREF = '/';
export const DEFAULT_HEADER_FULL_WIDTH = false;
export const DEFAULT_HEADER_HEIGHT = 64;
export const DEFAULT_CONTENT_PADDING = 32;

export const APP_THEME_FF = 'app-theme';
export const DEFAULT_APP_THEME = 'light';
