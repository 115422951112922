/* eslint-disable array-callback-return */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';

import AddSocietyRepresentative from 'modules/_shared/components/Modals/AddSocietyRepresentative';
import NoItemsAvailable from 'modules/_shared/components/Modals/NoItemsAvailable';

import SocietyRepresentativeRow from './SocietyRepresentativeRow';
import statusTypes from './statusTypes';

function SocietyRepresentative({ society, isAdmin, setPage, userId }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [representatives, setRepresentatives] = useState([]);
  const [seeNotCurrent, setSeeNotCurrent] = useState(false);

  const changeView = () => {
    setSeeNotCurrent(!seeNotCurrent);
  };

  const getStatus = (representative) => {
    if (!representative?.representativeStatus) {
      if (
        !representative?.endDate ||
        new Date(representative?.endDate) - new Date() > 0
      )
        return statusTypes.var.CURRENT;
      return statusTypes.var.REVOKED;
    }
    return representative?.representativeStatus;
  };

  useEffect(() => {
    dispatch(getDocuments({ society: society['_id'] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  useEffect(() => {
    if (society) {
      const filteredRepresentatives = society?.representatives?.filter(
        (representative) => {
          if (
            seeNotCurrent ||
            (!seeNotCurrent &&
              getStatus(representative) === statusTypes.var.CURRENT)
          ) {
            return representative;
          }
        }
      );

      setRepresentatives(
        filteredRepresentatives?.map((representative) => (
          <SocietyRepresentativeRow
            key={representative?.['_id']}
            userId={userId}
            representativeId={representative?.['_id']}
            societyId={society?.['_id']}
            socialDenomination={representative?.socialDenomination}
            nationality={representative?.nationality}
            initDate={representative?.initDate}
            endDate={representative?.endDate}
            birthDate={representative?.birthDate}
            nif={representative?.nif}
            address={representative?.address}
            email={representative?.email}
            tag={representative?.tag}
            comments={representative?.comments}
            representativeType={representative?.representativeType}
            representativeStatus={representative?.representativeStatus}
            documents={representative?.documents}
          />
        ))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society, seeNotCurrent]);

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title">
            {t('CompanyRepresentatives')}
          </h5>
          <div className="nk-block-des">
            <p>{t('TheseAreThePeopleOrCompanRepresentative')}</p>
          </div>
        </div>
      </div>

      <div className="nk-block">
        {isAdmin ? (
          <div className="nk-block-head">
            <div
              className="nk-block-head-content"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  store.dispatch(
                    setModal(
                      <AddSocietyRepresentative
                        societyId={society['_id']}
                        action="ADD"
                        userId={userId}
                      />
                    )
                  );
                  setPage(2);
                }}
                disabled={isDemo}
              >
                {t('AddRepresentative')}
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => changeView()}
                >
                  <em
                    className={
                      !seeNotCurrent ? 'icon ni ni-eye' : 'icon ni ni-eye-off'
                    }
                  />
                  <span>
                    {!seeNotCurrent ? t('SeeNotCurrent') : t('HideNotCurrent')}
                  </span>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="card card-bordered card-preview">
          <div className="table-responsive">
            <table className="nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
                  <th className="nk-tb-col border-radius-0">
                    <span>{t('NameAndSurname')}</span>
                  </th>
                  <th className="nk-tb-col border-radius-0 tb-col-xl">
                    <span>{t('Nationality')}</span>
                  </th>
                  <th className="nk-tb-col border-radius-0 tb-col-xl">
                    <span>{t('RepresentativeDate')}</span>
                  </th>
                  <th className="nk-tb-col border-radius-0 tb-col-xl">
                    <span>{t('RevocationDate')}</span>
                  </th>
                  <th className="nk-tb-col border-radius-0">
                    <span>{t('TypeOfAuthority')}</span>
                  </th>
                  <th className="nk-tb-col border-radius-0">
                    <span>{t('Status')}</span>
                  </th>
                  <th className="nk-tb-col border-radius-0">
                    <span>{t('Tag')}</span>
                  </th>
                  <th className="nk-tb-col border-radius-0 tb-col-xl text-center">
                    <span>{t('Doc')}</span>
                  </th>
                  {!isDemo && (
                    <th className="nk-tb-col border-radius-0">
                      <span />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {representatives?.length ? (
                  representatives
                ) : (
                  <NoItemsAvailable
                    mainMessage={t('NoRepresentativesAvailable')}
                  />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocietyRepresentative;
