/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import Select, { components } from 'react-select';
import { Tooltip } from 'react-tooltip';
import { sindicationTypes } from 'modules/partners/constants/sindicationTypes';

type Option = {
  label: string;
  value: string;
};

type Props = {
  value: Option;
  options: Option[];
  onChange: any;
  isAdmin: boolean;
  valueSindicationType: string;
  onChangeSindicationType: any;
  hasSelectedPartnerRepresentant: boolean;
};

const PersonalDataViewSindication: FC<Props> = ({
  value,
  options,
  onChange,
  isAdmin,
  valueSindicationType,
  onChangeSindicationType,
  hasSelectedPartnerRepresentant,
}) => {
  const { t } = useTranslate();

  const Group = (props: any) => (
    <div>
      <components.Group {...props} />
    </div>
  );

  return (
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-label w-100" htmlFor="sindication-shares">
          {t('SindicationShares')}
        </label>

        <div className="form-control-wrap">
          <Select
            closeMenuOnSelect
            value={value}
            className="react-select react-select-lg"
            options={options}
            components={{ Group }}
            onChange={onChange}
            placeholder={t('SelectPartner')}
            isDisabled={!isAdmin}
          />
        </div>

        <div className="custom-control custom-checkbox custom-control-sm align-items-center mt-2">
          <input
            type="checkbox"
            className="custom-control-input"
            name="sindication-type-checkbox"
            id="sindication-type-checkbox"
            value={valueSindicationType}
            checked={valueSindicationType === sindicationTypes.FULL}
            disabled={!isAdmin || !value || !hasSelectedPartnerRepresentant}
            onChange={onChangeSindicationType}
          />
          <label
            className="custom-control-label"
            htmlFor="sindication-type-checkbox"
          >
            <span>{t('MarkAsFullSindication')}</span>
            <em
              className="icon ni ni-info text-gray ml-2"
              data-tooltip-id="sindication-type-info"
            />
            <Tooltip
              id="sindication-type-info"
              place="bottom"
              style={{ zIndex: 9999, maxWidth: 400 }}
            >
              {t('MarkAsFullSindicationInfo')}
            </Tooltip>
          </label>
        </div>
      </div>
    </div>
  );
};

export default PersonalDataViewSindication;
