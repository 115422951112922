import userTypes from 'constants/userTypes';
import { BoardNewParticipant, BoardParticipantUserType, Partner } from 'types';
import { isRepresentedByPartner } from './isRepresentedByPartner';
import { isFullSindicated } from './isFullSindicated';

export const parsePartnersToParticipants = (
  partners: Partner[]
): BoardNewParticipant[] => {
  const participants =
    partners
      ?.filter((partner) => partner?.sharesCount?.actual > 0)
      ?.map((partner) => ({
        member: partner['_id'],
        name: partner?.name,
        email: partner?.email,
        userType: userTypes.PARTNER as BoardParticipantUserType,
        assists: false,
        isAbsent: false,
        isRepresented: isFullSindicated(partner) || false,
        hasDelegated: isFullSindicated(partner) || false,
        hasPartnerRepresentant:
          isFullSindicated(partner) &&
          !isRepresentedByPartner(partner?.sindication, partners),
        representative: isFullSindicated(partner) ? partner.sindication : null,
        delegationVote: null,
        delegationDocuments: isFullSindicated(partner)
          ? [partner.sindicationDocument]
          : [],
        votes: [],
      })) || [];

  return participants;
};
