import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { addBeneficiary } from 'modules/_shared/redux/modalsActions';
import { updateBeneficiary } from 'modules/beneficiaries/redux/beneficiaryActions';
import { addDocument } from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';

import documentTypes from 'constants/documentTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import { cleanValue } from 'constants/formats';
import invitationStatus from 'constants/invitationStatus';
import operationTypes from 'constants/operationTypes';
import signatureTypes from 'constants/signatureTypes';

import { getConsolidationPeriods } from 'utils/consolidationPeriods';
import { startSignProcess, toBase64 } from 'utils/docusignActions';
import { getSingleDocument } from 'utils/downloadDocument';
import { createBlobMerged } from 'utils/downloadMergedPDF';
import {
  getActualSociety,
  getUsedSharesFromPlan,
  isBeneficiaryAlready,
} from 'utils/filters';
import getEnvelopeData from 'utils/getEnvelopeData';
import { generateCalendarPlanTable } from 'utils/htmlTemplates';
import isValidEmail from 'utils/isValidEmail';
import normalizeCif from 'utils/normalizeCif';
import normalizeEmail from 'utils/normalizeEmail';
import hasShowDetails from 'utils/showDetails';
import trackEvent from 'utils/trackEvent';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';

import calculateTotalPeriods from 'modules/beneficiaries/utils/calculateTotalPeriods';
import createPeriodsArray from 'modules/beneficiaries/utils/createPeriodsArray';

import hasEmptyInvitationLetter from 'modules/beneficiaries/utils/hasEmptyInvitationLetter';

import getDocumentBlob from 'modules/_shared/components/CKEditor/utils/getDocumentBlob';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import AddBeneficiaryModalHeader from './components/AddBeneficiaryModalHeader';
import AddBeneficiaryModalFooter from './components/AddBeneficiaryModalFooter';
import AddBeneficiaryModalPersonalInfoView from './components/AddBeneficiaryModalPersonalInfoView';
import AddBeneficiaryModalPlanView from './components/AddBeneficiaryModalPlanView';
import AddBeneficiaryModalConditionsView from './components/AddBeneficiaryModalConditionsView';
import AddBeneficiaryModalUnitsView from './components/AddBeneficiaryModalUnitsView';
import AddBeneficiaryModalLaboralCategoryView from './components/AddBeneficiaryModalLaboralCategoryView';
import AddBeneficiaryModalDocumentationView from './components/AddBeneficiaryDocumentationView';
import { getSharesFromPercent } from './utils/getSharesFromPercent';
import { getPercentFromShares } from './utils/getPercentFromShares';
import { useGetConsolidationPeriods } from './hook/useGetConsolidationPeriods';

import '../Modals.scss';
import './AddBeneficiary.scss';
import { actions } from './constants/actions';

const consolidationTypes = {
  BY_UNITS: 'BY_UNITS',
  BY_PERCENT: 'BY_PERCENT',
};

function AddBeneficiaryModal({
  action = actions.ADD,
  currentBeneficiary,
  isDraft = false,
}) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state.user);
  const stockPlans = useSelector((state) => state.plans);
  const tenderOffers = useSelector((state) => state.tenderOffers);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [name, setName] = useState('');
  const [cif, setCif] = useState('');
  const [email, setEmail] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [plan, setPlan] = useState('');
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [invitationDate, setInvitationDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [shares, setShares] = useState();
  const [file, setFile] = useState();
  const [currentPlan, setCurrentPlan] = useState('');
  const [currentPlanShares, setCurrentPlanShares] = useState(0);
  const [amount, setAmount] = useState(0);

  const [currentPlanLaboralCategories, setCurrentPlanLaboralCategories] =
    useState([]);
  const [laboralCategory, setLaboralCategory] = useState('');
  const [salary, setSalary] = useState('');

  const [sendInvitation, setSendInvitation] = useState(false);
  const [invitationCode, setInvitationCode] = useState('');
  const [invitationStatusType, setInvitationStatusType] = useState();

  // Custom conditions
  const [hasCustomConditions, setHasCustomConditions] = useState(false);
  const [sharePrice, setSharePrice] = useState();
  const [isFixedPrice, setIsFixedPrice] = useState(false);
  const [cliff, setCliff] = useState(0);
  const [vestingPeriod, setVestingPeriod] = useState();
  const [hasEndMonthConsolidation, setHasEndMonthConsolidation] =
    useState(false);
  const [hasDecimalConsolidation, setHasDecimalConsolidation] = useState(false);
  const [consolidation, setConsolidation] = useState();
  const [customConsolidation, setCustomConsolidation] = useState(false);

  const [customConsolidationType, setCustomConsolidationType] = useState(null);

  const [attachmentDocument, setAttachmentDocument] = useState(null);
  const [validEmail, setValidEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const consolidationPeriods = useGetConsolidationPeriods({
    beneficiary: currentBeneficiary,
    plan: currentPlan,
    totalShares: shares,
  });

  const { consolidationPeriodsByPercent, consolidationPeriodsByUnits } =
    consolidationPeriods.data;
  const { setConsolidationPeriodsByPercent, setConsolidationPeriodsByUnits } =
    consolidationPeriods.actions;

  const handleUpdateConsolidationPeriodByPercent = (index, value) => {
    const newConsolidationPeriods = [...consolidationPeriodsByPercent];
    newConsolidationPeriods[index] = value;
    setConsolidationPeriodsByPercent(newConsolidationPeriods);
  };

  const handleUpdateConsolidationPeriodByUnits = (index, value) => {
    const newConsolidationPeriods = [...consolidationPeriodsByUnits];
    newConsolidationPeriods[index] = value;
    setConsolidationPeriodsByUnits(newConsolidationPeriods);
  };

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleSelectPeriodsByPercent = () => {
    setCustomConsolidationType(consolidationTypes.BY_PERCENT);
  };

  const handleSelectPeriodsByUnits = () => {
    setCustomConsolidationType(consolidationTypes.BY_UNITS);
  };

  const handleChangeEndMonthConsolidation = () => {
    setHasEndMonthConsolidation(!hasEndMonthConsolidation);
  };

  const handleConsolidationPeriodsChange = (
    index,
    value,
    consolidationType
  ) => {
    const numericValue = +value;
    if (consolidationType === consolidationTypes.BY_PERCENT) {
      handleUpdateConsolidationPeriodByPercent(index, numericValue);

      const sharesValue = getSharesFromPercent({
        percent: numericValue,
        totalShares: shares,
      });

      handleUpdateConsolidationPeriodByUnits(index, sharesValue);
    } else {
      handleUpdateConsolidationPeriodByUnits(index, numericValue);

      const percentValue = getPercentFromShares({
        units: numericValue,
        totalShares: shares,
      });
      handleUpdateConsolidationPeriodByPercent(index, percentValue);
    }
  };

  const handleChangeName = (event) => {
    const { value } = event.target;
    setName(value);
  };

  const handleChangeCif = (event) => {
    const { value } = event.target;

    const normalicedCif = normalizeCif(value);
    setCif(normalicedCif);
  };

  const handleChangeEmail = (event) => {
    const { value } = event.target;

    const normalicedEmail = normalizeEmail(value);
    setEmail(normalicedEmail);

    const isValid = isValidEmail(normalicedEmail);
    setValidEmail(isValid);
  };

  const handleChangeAddress = (event) => {
    const { value } = event.target;
    setAddressLine(value);
  };

  const handleChangeJobTitle = (event) => {
    const { value } = event.target;
    setJobTitle(value);
  };

  const handleChangePlan = (value) => {
    setPlan(value);
  };

  const handleChangeHasCustomConditions = () => {
    setHasCustomConditions((prevState) => !prevState);
  };

  const handleChangeSharePrice = (event) => {
    const { value } = event.target;
    setSharePrice(value);
  };

  const handleChangeIsFixedPrice = () => {
    setIsFixedPrice((prevState) => !prevState);
  };

  const handleChangeCliff = (value) => {
    setCliff(value);
  };

  const handleChangeVestingPeriod = (value) => {
    setVestingPeriod(value);
  };

  const handleChangeConsolidation = (value) => {
    setConsolidation(value);
  };

  const handleChangeHasDecimalConsolidation = () => {
    setHasDecimalConsolidation((prevState) => !prevState);
  };

  const handleChangeHasCustomConsolidation = () => {
    setCustomConsolidation((prevState) => !prevState);
  };

  const handleChangeDate = (event) => {
    const { value } = event.target;
    setDate(value);
  };

  const handleChangeLaboralCategory = (value) => {
    setLaboralCategory(value);
  };

  const handleChangeSalary = (event) => {
    const { value } = event.target;
    setSalary(value);
  };

  const getAttachedDocument = async (documentId) => {
    if (documentId) {
      const attachmentBlob = await getSingleDocument(documentId);
      setAttachmentDocument(attachmentBlob);
    }
  };

  const getCurrentSendInvitation = (currentPlan) => {
    if (currentPlan?.sendInvitationToAll) {
      return true;
    }

    if (
      currentPlan?.hasInvitation &&
      currentPlan?.sendInvitationToAll === undefined
    ) {
      return true;
    }

    return false;
  };

  const handleChangeInvitationDate = (event) => {
    const { value } = event.target;
    setInvitationDate(value);
  };

  const handleChangeFile = (file) => {
    setFile(file);
  };

  const handleChangeSendInvitation = () => {
    setSendInvitation((prevState) => !prevState);
  };

  const handleSaveBeneficiary = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const parsedCategory =
        currentPlan?.hasLaboralCategories && laboralCategory
          ? JSON.parse(laboralCategory)
          : { name: '', percent: 0 };

      const consolidationPeriods =
        customConsolidationType === consolidationTypes.BY_PERCENT
          ? consolidationPeriodsByPercent
          : consolidationPeriodsByUnits;

      const isCustomDilutionByUnits = !!(
        customConsolidationType === consolidationTypes.BY_UNITS
      );

      const newBeneficiary = {
        email,
        society: actualSociety?.['_id'],
        societyLogo: actualSociety?.additional?.['logo'],
        userId: user['_id'],
        userEmail: user.email,
        societyName: actualSociety?.name,
        address: {
          line1: addressLine,
          zip: '',
          city: '',
          state: '',
          country: '',
        },
        cif,
        plan,
        planName: currentPlan?.name,
        planType: currentPlan?.planType,
        planStartDate: new Date(date),
        amount,
        sharesCount: { future: shares },
        name,
        jobTitle,
        laboralCategory: {
          name: parsedCategory.name,
          percent: parsedCategory.percent,
        },
        salary,
        hasInvitation: currentPlan?.hasInvitation,
        invitationDate: currentPlan?.hasInvitation
          ? new Date(invitationDate)
          : null,
        invitationCode,
        invitationStatus: invitationStatusType,
        isDraft,
      };

      if (hasCustomConditions) {
        newBeneficiary.customConditions = {
          sharePrice,
          isFixedPrice,
          cliff,
          vestingPeriod,
          consolidation,
          customConsolidation,
          consolidationPeriods,
          isCustomDilutionByUnits,
          hasEndMonthConsolidation,
          hasDecimalConsolidation,
        };
      }

      if (action === actions.ADD || action === actions.DUPLICATE) {
        let validBeneficiary = true;
        const isCifAlready = isBeneficiaryAlready(cif, plan, actualSociety);

        if (!isDraft && hasEmptyInvitationLetter(currentPlan)) {
          return Swal.fire({
            icon: 'warning',
            title: `<h4 class="nk-modal-title">${t(
              'EmptyInvitationLetter'
            )}</h4><br /><p class="caption-text">${t(
              'EmptyInvitationLetterMessage'
            )}</p>`,
            confirmButtonText: t('Accept'),
            confirmButtonColor: '#6576ff',
            allowOutsideClick: false,
          });
        }

        if (isCifAlready) {
          await Swal.fire({
            icon: 'warning',
            title: `<h4 class="nk-modal-title">${t('BeneficiaryAlreadyExists', {
              cif,
              planName: currentPlan?.name,
            })}</h4>`,
            confirmButtonText: t('Accept'),
            cancelButtonText: t('Cancel'),
            confirmButtonColor: '#6576ff',
            allowOutsideClick: false,
            showCancelButton: true,
          }).then((result) => {
            if (!result.isConfirmed) {
              validBeneficiary = false;
            }
          });
        }

        if (validBeneficiary) {
          let uploadedDocument = '';

          if (file) {
            const fileName = `${operationTypes.ADD_BENEFICIARY_FILENAME}_${file.name}`;
            const newDocument = {
              file,
              date,
              name: fileName,
              author: user['_id'],
              society: actualSociety['_id'],
              size: file?.size || 0,
              lastAccess: new Date(),
              category: documentTypes.LETTERS,
              isGenerated: true,
              fileType: fileTypes[file?.type],
              description: t('DocumentLinkedToBeneficiary'),
            };
            uploadedDocument = await store.dispatch(addDocument(newDocument));
          }

          const documents = [];
          if (uploadedDocument?.['_id']) {
            documents.push(uploadedDocument?.['_id']);
          }

          let fileName = '';
          let invitationFile = '';
          let invitationDocument = '';
          let hasSocietySign = false;

          const shouldSendInvitation =
            currentPlan?.hasInvitation &&
            currentPlan?.invitationModel &&
            !isDraft &&
            sendInvitation;

          if (shouldSendInvitation) {
            const invitationUpdated = updateDocumentWithValues(
              currentPlan?.invitationModel?.html,
              tags({
                plan: currentPlan,
                society: actualSociety,
                beneficiary: newBeneficiary,
                partner: null,
                board: null,
              })
            );

            fileName = `Carta_de_Invitación_${newBeneficiary.name}_${currentPlan.name}.pdf`;
            const invitationModel = invitationUpdated.html;
            const {
              hasConsolidationCalendar,
              hasConsolidationCalendarNoStatus,
              hasConsolidationCalendarNoStatusENES,
            } = invitationUpdated;
            hasSocietySign = invitationUpdated.hasSocietySign;

            const mainBlob = await getDocumentBlob(invitationModel);

            const mainFile = new File([mainBlob], fileName, {
              type: 'application/pdf',
            });

            const hasTables =
              hasConsolidationCalendar ||
              hasConsolidationCalendarNoStatus ||
              hasConsolidationCalendarNoStatusENES;
            const tableFiles = [];
            if (hasTables) {
              const consolidationTables = generateCalendarPlanTable(
                getConsolidationPeriods(newBeneficiary, currentPlan),
                {
                  hasConsolidationCalendar,
                  hasConsolidationCalendarNoStatus,
                  hasConsolidationCalendarNoStatusENES,
                }
              );

              // eslint-disable-next-line no-restricted-syntax
              for (const table of consolidationTables) {
                // eslint-disable-next-line no-await-in-loop
                const tableBlob = await getDocumentBlob(table);

                const tableFile = new File([tableBlob], fileName, {
                  type: 'application/pdf',
                });
                tableFiles.push(tableFile);
              }
            }

            let attachmentFile;
            if (attachmentDocument) {
              attachmentFile = new File([attachmentDocument], fileName, {
                type: 'application/pdf',
              });
            }

            const mergedBlob =
              tableFiles?.length > 0 || attachmentFile
                ? await createBlobMerged({
                    documents: [mainFile, ...tableFiles, attachmentFile],
                  })
                : mainBlob;

            invitationFile = new File([mergedBlob], fileName, {
              type: 'application/pdf',
            });

            const newDocument = {
              name: fileName,
              file: invitationFile,
              size: invitationFile?.size || 0,
              date: new Date(),
              fileType: fileTypes.PDF,
              lastAccess: new Date(),
              isGenerated: true,
              author: user['_id'],
              society: actualSociety['_id'],
              category: documentTypes.LETTERS,
              description: t('DocumentGenerated'),
            };

            invitationDocument = await store.dispatch(addDocument(newDocument));
            if (invitationDocument?.['_id']) {
              documents.push(invitationDocument?.['_id']);
            }
          }

          const uploadedBeneficiary = await store.dispatch(
            addBeneficiary({
              ...newBeneficiary,
              documents,
            })
          );

          if (shouldSendInvitation) {
            const signFileBase64 = await toBase64(invitationFile);

            const signers = [
              {
                id: uploadedBeneficiary['_id'],
                email: newBeneficiary?.email,
                name: newBeneficiary?.name,
                role: 'BENEFICIARY',
                type: signatureTypes.INVITATION_LETTER,
              },
            ];

            if (hasSocietySign) {
              signers.push({
                id: '',
                email: currentPlan?.invitationExtraSigner?.email || '',
                name: currentPlan?.invitationExtraSigner?.name || '',
                role: 'SOCIETY',
                type: signatureTypes.FREE_SIGNATURE,
              });
            }

            const beneficiaries = [
              { id: uploadedBeneficiary['_id'], planId: currentPlan['_id'] },
            ];

            const envelopeData = getEnvelopeData({
              signers,
              signType: signatureTypes.INVITATION_LETTER,
              fileName,
              fileType: fileTypes.PDF,
              linkedType: 'BENEFICIARY',
              role: 'BENEFICIARIES',
              userId: user?._id,
              societyId: actualSociety?._id,
              beneficiaries,
              documentId: invitationDocument['_id'],
            });

            const signProcessData = {
              signers,
              societyName: actualSociety?.name,
              documentName: fileName,
              planName: currentPlan?.name,
              language: actualSociety?.societyLanguage || 'es',
              signatureType: signatureTypes.INVITATION_LETTER,
              signatureFile: signFileBase64,
              signatureFileName: fileName,
              envelopeData,
            };

            await startSignProcess(signProcessData);
          }

          trackEvent(mixpanel, eventTypes.ADD_BENEFICIARY, {
            userId: user?._id,
            userName: user?.name,
            userEmail: user?.email,
            societyId: actualSociety?._id,
            societyName: actualSociety?.name,
            operation: eventTypes.ADD_BENEFICIARY,
          });

          dispatch(setModal(null));

          dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
        }
      } else if (action === actions.EDIT) {
        if (cif !== currentBeneficiary?.cif) {
          const isCifAlready = isBeneficiaryAlready(cif, plan, actualSociety);
          if (isCifAlready) {
            dispatch(addAlert(alertBodyTypes.DUPLICATED_CIF));
            return;
          }
        }

        const parsedCategory =
          currentPlan?.hasLaboralCategories && laboralCategory
            ? JSON.parse(laboralCategory)
            : { name: '', percent: 0 };

        const updatedBeneficiary = {
          planStartDate: new Date(date),
          amount,
          sharesCount: { future: shares },
          laboralCategory: {
            name: parsedCategory.name,
            percent: parsedCategory.percent,
          },
          salary,
        };
        if (hasCustomConditions) {
          updatedBeneficiary.customConditions = {
            sharePrice,
            isFixedPrice,
            cliff,
            vestingPeriod,
            consolidation,
            customConsolidation,
            consolidationPeriods,
            isCustomDilutionByUnits,
            hasEndMonthConsolidation,
            hasDecimalConsolidation,
          };
        }
        await store.dispatch(
          updateBeneficiary({
            id: currentBeneficiary['_id'],
            data: {
              ...updatedBeneficiary,
              userId: user['_id'],
              societyId: actualSociety['_id'],
              cascade: false,
            },
            showAlert: false,
          })
        );
        getActualSociety(user, actualSociety['_id']);
        dispatch(setModal(null));
      } else {
        dispatch(setModal(null));

        dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      }
    } catch (error) {
      console.log('Error adding beneficiary', error, error?.message);
      dispatch(addAlert(alertBodyTypes.ERROR_ADDING_BENEFICIARY));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeShares = (event) => {
    const { value } = event.target;

    const numberValue = Number(value);
    if (numberValue > 0 && currentPlanShares >= numberValue) {
      setShares(numberValue);
    }
    if (value === '') {
      setShares();
    }
  };

  useEffect(() => {
    const selectedPlan = stockPlans.find((p) => p['_id'] === plan);
    if (selectedPlan) {
      if (selectedPlan?.hasInvitation) {
        const generatedCode = uuidv4();
        setInvitationCode(generatedCode);
        setInvitationStatusType(invitationStatus.PENDING.value);
      } else {
        setInvitationStatusType('');
      }
    }
    setCurrentPlan(selectedPlan);
    getAttachedDocument(selectedPlan?.invitationAttachedDocument);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  useEffect(() => {
    if (action === actions.WATCH) return;
    if (!customConsolidation) return;

    if (consolidation !== 0.033) {
      const totalPeriods = calculateTotalPeriods(vestingPeriod, consolidation);
      const newConsolidationPeriods = createPeriodsArray(totalPeriods);

      setConsolidationPeriodsByPercent(newConsolidationPeriods);
      setConsolidationPeriodsByUnits(newConsolidationPeriods);
    } else {
      setCustomConsolidation(false);
      setConsolidationPeriodsByPercent([]);
      setConsolidationPeriodsByUnits([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vestingPeriod, consolidation, customConsolidation]);

  useEffect(() => {
    if (currentPlan) {
      const usedShares = getUsedSharesFromPlan(
        currentPlan,
        actualSociety,
        tenderOffers,
        isDraft
      );

      const categories = currentPlan.laboralCategories;
      let availableShares = currentPlan.sharesTotal - usedShares;

      if (action === actions.EDIT && currentBeneficiary)
        availableShares += currentBeneficiary?.sharesCount?.future;

      setCurrentPlanShares(Math.floor(availableShares)); // round to integer down
      setCurrentPlanLaboralCategories(categories);
      if (action !== actions.EDIT) {
        setSalary('');
        setShares(0);
      }
      setSendInvitation(getCurrentSendInvitation(currentPlan));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, currentPlan]);

  useEffect(() => {
    if (laboralCategory && laboralCategory === 'default_option')
      setLaboralCategory({});
    else if (currentPlan && salary && Object.entries(laboralCategory).length) {
      const category = JSON.parse(laboralCategory);
      const cleanSalary = cleanValue(salary);
      const years = currentPlan?.annualUnits
        ? currentPlan?.vestingPeriod / 12
        : 1;
      if (hasCustomConditions && !isFixedPrice && sharePrice) {
        const units = Math.floor(
          (category.percent * cleanSalary) / 100 / sharePrice
        );
        setShares(Math.floor(units * years));
      } else if (!currentPlan?.isFixedPrice) {
        const units = Math.floor(
          (category.percent * cleanSalary) / 100 / currentPlan?.sharePrice
        );
        setShares(Math.floor(units * years));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salary, laboralCategory, currentPlan, isFixedPrice, sharePrice]);

  useEffect(() => {
    let computedAmount = 0;
    if (hasCustomConditions && sharePrice) {
      computedAmount = isFixedPrice ? sharePrice : shares * sharePrice;
    } else {
      computedAmount = currentPlan?.isFixedPrice
        ? currentPlan?.sharePrice
        : shares * currentPlan?.sharePrice;
    }
    setAmount(computedAmount);
  }, [sharePrice, currentPlan, shares, isFixedPrice, hasCustomConditions]);

  useEffect(() => {
    if (action === actions.DUPLICATE) {
      setPlan(currentBeneficiary?.plan);
      setDate(
        format(new Date(currentBeneficiary?.planStartDate), 'yyyy-MM-dd')
      );
      setShares(currentBeneficiary?.sharesCount?.future);
    }
    if (action === actions.EDIT) {
      setName(currentBeneficiary?.name);
      setCif(currentBeneficiary?.cif);
      setEmail(currentBeneficiary?.email);
      setJobTitle(currentBeneficiary?.jobTitle || '');
      setPlan(currentBeneficiary?.plan);
      if (currentBeneficiary.invitationDate) {
        setInvitationDate(
          format(new Date(currentBeneficiary?.invitationDate), 'yyyy-MM-dd')
        );
      }
      if (currentBeneficiary?.customConditions) {
        setHasCustomConditions(true);
        setIsFixedPrice(currentBeneficiary?.customConditions?.isFixedPrice);
        setSharePrice(currentBeneficiary?.customConditions?.sharePrice);
        setCliff(currentBeneficiary?.customConditions?.cliff);
        setVestingPeriod(currentBeneficiary?.customConditions?.vestingPeriod);
        setCustomConsolidation(
          currentBeneficiary?.customConditions?.customConsolidation
        );
        setHasEndMonthConsolidation(
          currentBeneficiary?.customConditions?.hasEndMonthConsolidation
        );
        setHasDecimalConsolidation(
          currentBeneficiary?.customConditions?.hasDecimalConsolidation
        );
        setConsolidation(currentBeneficiary?.customConditions?.consolidation);

        const { isCustomDilutionByUnits } =
          currentBeneficiary?.customConditions;

        setCustomConsolidationType(
          isCustomDilutionByUnits
            ? consolidationTypes.BY_UNITS
            : consolidationTypes.BY_PERCENT
        );
      }
      setDate(
        format(new Date(currentBeneficiary?.planStartDate), 'yyyy-MM-dd')
      );
      setShares(currentBeneficiary?.sharesCount?.future);
      setLaboralCategory(
        JSON.stringify(currentBeneficiary?.laboralCategory) || ''
      );
      setSalary(cleanValue(currentBeneficiary?.salary) || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hasShowDetails()) {
    console.log('🚀 cclog ~ currentPlan:', {
      hasInvitation: currentPlan?.hasInvitation,
      sendInvitationToAll: currentPlan?.sendInvitationToAll,
      sendInvitation,
    });
  }

  return (
    <>
      <AddBeneficiaryModalHeader
        action={action}
        handleCloseModal={handleCloseModal}
      />
      <Modal.Body>
        {isLoading && <CustomLoading />}
        <div className="row mb-4">
          <div className="col">
            <AddBeneficiaryModalPersonalInfoView
              action={action}
              name={name}
              cif={cif}
              email={email}
              address={addressLine}
              jobTitle={jobTitle}
              validEmail={validEmail}
              handleChangeName={handleChangeName}
              handleChangeCif={handleChangeCif}
              handleChangeEmail={handleChangeEmail}
              handleChangeAddress={handleChangeAddress}
              handleChangeJobTitle={handleChangeJobTitle}
            />

            <AddBeneficiaryModalPlanView
              action={action}
              currentPlan={currentPlan}
              planId={plan}
              societyId={actualSociety['_id']}
              hasCustomConditions={hasCustomConditions}
              isDraft={isDraft}
              handleChangePlan={handleChangePlan}
              handleChangeHasCustomConditions={handleChangeHasCustomConditions}
            />

            {hasCustomConditions && (
              <AddBeneficiaryModalConditionsView
                action={action}
                sharePrice={sharePrice}
                isFixedPrice={isFixedPrice}
                cliff={cliff}
                vestingPeriod={vestingPeriod}
                consolidation={consolidation}
                hasEndMonthConsolidation={hasEndMonthConsolidation}
                hasDecimalConsolidation={hasDecimalConsolidation}
                customConsolidation={customConsolidation}
                customConsolidationType={customConsolidationType}
                consolidationPeriodsByPercent={consolidationPeriodsByPercent}
                consolidationPeriodsByUnits={consolidationPeriodsByUnits}
                handleChangeSharePrice={handleChangeSharePrice}
                handleChangeIsFixedPrice={handleChangeIsFixedPrice}
                handleChangeCliff={handleChangeCliff}
                handleChangeVestingPeriod={handleChangeVestingPeriod}
                handleChangeConsolidation={handleChangeConsolidation}
                handleChangeEndMonthConsolidation={
                  handleChangeEndMonthConsolidation
                }
                handleChangeHasDecimalConsolidation={
                  handleChangeHasDecimalConsolidation
                }
                handleChangeHasCustomConsolidation={
                  handleChangeHasCustomConsolidation
                }
                handleSelectPeriodsByPercent={handleSelectPeriodsByPercent}
                handleConsolidationPeriodsChange={
                  handleConsolidationPeriodsChange
                }
                handleSelectPeriodsByUnits={handleSelectPeriodsByUnits}
              />
            )}

            {currentPlan?.hasLaboralCategories && (
              <AddBeneficiaryModalLaboralCategoryView
                laboralCategory={laboralCategory}
                salary={salary}
                currentPlanLaboralCategories={currentPlanLaboralCategories}
                handleChangeLaboralCategory={handleChangeLaboralCategory}
                handleChangeSalary={handleChangeSalary}
              />
            )}

            <AddBeneficiaryModalUnitsView
              date={date}
              planId={plan}
              currentPlanShares={currentPlanShares}
              shares={shares}
              laboralCategory={laboralCategory}
              currentPlan={currentPlan}
              hasCustomConditions={hasCustomConditions}
              sharePrice={sharePrice}
              amount={amount}
              handleChangeDate={handleChangeDate}
              handleChangeShares={handleChangeShares}
            />

            <AddBeneficiaryModalDocumentationView
              action={action}
              currentPlan={currentPlan}
              invitationDate={invitationDate}
              sendInvitation={sendInvitation}
              file={file}
              handleChangeInvitationDate={handleChangeInvitationDate}
              handleChangeFile={handleChangeFile}
              handleChangeSendInvitation={handleChangeSendInvitation}
            />

            <span className="sub-text text-dark">
              {t('ThisPriceIsSubjectToThePlanAndLetter')}
            </span>
          </div>
        </div>
      </Modal.Body>
      <AddBeneficiaryModalFooter
        action={action}
        name={name}
        cif={cif}
        email={email}
        plan={plan}
        date={date}
        shares={shares}
        validEmail={validEmail}
        handleSaveBeneficiary={handleSaveBeneficiary}
      />
    </>
  );
}

export default AddBeneficiaryModal;
