/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import i18n from 'i18n/config';
import { store } from 'redux/store';
import bigDecimal from 'js-big-decimal';

import {
  getSocietyById,
  setSociety,
} from 'modules/society/redux/societyActions';

import userTypes from 'constants/userTypes';
import poolTypes from 'constants/poolTypes';
import voteValues from 'constants/voteValues';
import draftTypes from 'constants/draftTypes';
import periodTypes from 'constants/periodTypes';
import operationTypes from 'constants/operationTypes';
import transactionTypes from 'constants/transactionTypes';
import presetRecipients from 'constants/presetRecipients';
import instructionTypes from 'constants/instructionTypes';

import statusTypes from 'modules/society/components/SocietyDirectors/statusTypes';
import getEligibleType from 'modules/transactions/pages/TenderOfferDetails/getEligibleType';

import {
  tenderEligibleTypes,
  eligiblesSubgroupTypes,
  tenderOfferParticipantStatus,
  tenderParticipantStatusTypes,
} from './tenderOfferTypes';

import {
  employeesGroupTypes,
  employeesSubgroupTypes,
} from './employeesFilterTypes';

import {
  getEmployeeFirstStartDate,
  getEmployeeSalaryAmount,
} from './employees';

import {
  calculateConsolidationPercent,
  getConsolidationPeriods,
} from './consolidationPeriods';
import { getSocietySenderDomain } from './emails';

export async function getActualSociety(user, societyId) {
  if (!user || !societyId) return null;

  const society = await getSocietyById(societyId);
  const currentSociety = society;

  const result = {
    society,
  };
  const {
    usersWithAccess,
    adminPermissions,
    guests,
    societies: {
      participated,
      administrated,
      beneficiated,
      invested,
      represented,
      invited,
    },
  } = user;

  result.role = {};

  result.role.isAdmin = !!administrated?.find(
    (society) => society === societyId
  );
  result.role.isPartner = !!participated?.find(
    (society) => society === societyId
  );
  result.role.isBeneficiary = !!beneficiated?.find(
    (society) => society === societyId
  );
  result.role.isInvestor = !!invested?.find((society) => society === societyId);
  result.role.isRepresentant = !!represented?.find(
    (society) => society === societyId
  );
  result.role.isGuest = !!invited?.find((society) => society === societyId);
  result.role.isHolder = !!participated?.find(
    (society) =>
      !!(society === societyId) && !!(currentSociety.isSocietyHolding === true)
  );
  result.role.isDemo = !!society?.demos?.find(
    (demo) => demo.userId === user['_id']
  );

  // TODO: validate if user Demo validation needs to be done here
  usersWithAccess.forEach((currentUser) => {
    result.role.isAdmin =
      result.role.isAdmin ||
      !!currentUser.societies?.administrated?.find(
        (society) => society === societyId
      );
    result.role.isPartner =
      result.role.isPartner ||
      !!currentUser.societies?.participated?.find(
        (society) => society === societyId
      );
    result.role.isBeneficiary =
      result.role.isBeneficiary ||
      !!currentUser.societies?.beneficiated?.find(
        (society) => society === societyId
      );
    result.role.isInvestor =
      result.role.isInvestor ||
      !!currentUser.societies?.invested?.find(
        (society) => society === societyId
      );
    result.role.isRepresentant =
      result.role.isRepresentant ||
      !!currentUser.societies?.represented?.find(
        (society) => society === societyId
      );
    result.role.isGuest =
      result.role.isGuest ||
      !!currentUser.societies?.invited?.find(
        (society) => society === societyId
      );
    result.role.isHolder =
      result.role.isHolder ||
      !!currentUser.societies?.participated?.find(
        (society) =>
          !!(society === societyId) &&
          !!(currentSociety.isSocietyHolding === true)
      );
  });

  const societyGuests = guests?.find(
    (guest) =>
      guest?.societies?.includes(societyId) && guest?.email === user.email
  );

  const currentSocietyGuestPermissions = societyGuests?.guestPermissions.find(
    (permission) => permission.society === societyId
  );

  const currentSocietyAdminPermissions = adminPermissions.find(
    (permission) => permission.society === societyId
  );

  result.permissions =
    (result.role.isAdmin && currentSocietyAdminPermissions?.permissions) ||
    (result.role.isGuest && currentSocietyGuestPermissions?.permissions) ||
    {};

  store.dispatch(setSociety(result, user));
}

export function getPartnerSince(societyId, user) {
  if (!societyId || !user) return null;

  const { participated } = user.societies;
  const society = participated.find((society) => society['_id'] === societyId);
  const partner = society.partners.find(
    (partner) => partner.id['_id'] === user['_id']
  );
  const partnerNumber = partner.id.partnerNumber.find(
    (partnerNumber) => partnerNumber.society === societyId
  );

  return partnerNumber ? partnerNumber.partnerSince : null;
}

export function getActualPartner(society, partnerId) {
  if (!society || !partnerId) return null;
  const partner = society.partners.find(
    (partner) => partner['_id'] === partnerId
  );
  return partner || { _id: null };
}

export function getUserPartnerSince(userId, society) {
  if (!society || !userId) return null;
  const resultDate = society?.partners?.reduce((acc, partner) => {
    let result = acc;
    if (
      (partner?.user === userId || partner?.otherUsers?.includes(userId)) &&
      partner?.incorporationDate
    ) {
      if (!result) result = partner?.incorporationDate;
      else
        result =
          new Date(partner?.incorporationDate) < new Date(result)
            ? partner.incorporationDate
            : result;
    }
    return result;
  }, null);
  return resultDate;
}

export function getNameInitials(name) {
  if (!name) return '';
  const [firstName, lastName] = name?.split(' ');
  return `${firstName && firstName[0]}${lastName && lastName[0]}`;
}

export function getUserSocietyPercent(userId, society) {
  if (!userId || !society) return null;
  const partnersActualSharesCount = society?.partners?.reduce(
    (acc, partner) => {
      let result = acc;
      if (partner.user === userId || partner?.otherUsers?.includes(userId))
        result += partner.sharesCount?.actual;
      return result;
    },
    0
  );
  const societyActualSharesCount = society.sharesCount?.actual;
  return (partnersActualSharesCount * 100) / societyActualSharesCount || 0;
}
export function getUserSocietyShares(userId, society) {
  if (!userId || !society) return null;
  const userActualSharesCount = society?.partners?.reduce((acc, partner) => {
    let result = acc;
    if (partner.user === userId || partner?.otherUsers?.includes(userId))
      result += partner.sharesCount?.actual;
    return result;
  }, 0);

  return userActualSharesCount;
}

export function getUserSocietyPercentFD(userId, society) {
  if (!userId || !society) return null;
  const partnersSharesCount = society?.partners?.reduce((acc, partner) => {
    let result = acc;
    if (partner.user === userId || partner?.otherUsers?.includes(userId))
      result +=
        partner.sharesCount?.actual +
        partner.sharesCount?.future +
        partner.sharesCount?.drafts;
    return result;
  }, 0);
  const societySharesCount =
    society.sharesCount?.actual +
    society.sharesCount?.future +
    society.sharesCount?.drafts;
  return (partnersSharesCount * 100) / societySharesCount || 0;
}

export function getPartnerRegistrationDate(society, partner) {
  if (!society || !partner) return null;
  const op = society?.operations?.find(
    (operation) =>
      (operation.operationType === operationTypes.CONSTITUTION ||
        operation.operationType === operationTypes.CAPITAL_INCREASE ||
        operation.operationType === operationTypes.SELL_PARTICIPATION ||
        operation.operationType === operationTypes.HERITAGE ||
        operation.operationType === operationTypes.DONATION) &&
      partner.operations.includes(operation['_id'])
  );
  if (op) return op.date;
  return null;
}

export function getUserRole(userId, society) {
  const { administrators, partners, beneficiaries } = society;
  const userRole = {
    isAdmin: false,
    isPartner: false,
    isBeneficiary: false,
  };
  if (administrators.find((admin) => admin['_id'] === userId)) {
    userRole.isAdmin = true;
  }
  if (partners.find((partner) => partner.id['_id'] === userId)) {
    userRole.isPartner = true;
  }
  if (beneficiaries.find((beneficiary) => beneficiary.id['_id'] === userId)) {
    userRole.isBeneficiary = true;
  }
  return userRole;
}

// This method read society.partners._id (instead of society.partners.id._id)
export function getPartnerById(society, partnerId) {
  if (!society || !partnerId) return null;

  const partner = society.partners.find(
    (partner) => partner['_id'] === partnerId
  );
  return partner || { id: null };
}

export function getPartnerByCif(society, partnerCif) {
  if (!society || !partnerCif) return null;

  const partner = society.partners.find(
    (partner) => partner.cif === partnerCif
  );
  return partner || {};
}

export function getActualBeneficiary(society, planId, beneficiaryId) {
  if (!society || !planId || !beneficiaryId) return null;

  const actualBeneficiary = society.beneficiaries.find(
    (beneficiary) =>
      beneficiary['_id'] === beneficiaryId && beneficiary.plan === planId
  );
  return actualBeneficiary || { id: null };
}

export function getBeneficiaryAmount(beneficiary, plan) {
  if (!beneficiary || !plan) return null;
  const units = beneficiary?.isCanceled
    ? beneficiary?.finalConsolidatedUnits
    : beneficiary?.sharesCount?.future;
  let amount = 0;
  if (
    beneficiary?.customConditions &&
    beneficiary?.customConditions?.sharePrice
  ) {
    amount = beneficiary?.customConditions?.isFixedPrice
      ? beneficiary?.customConditions?.sharePrice
      : units * beneficiary?.customConditions?.sharePrice;
  } else {
    amount = plan?.isFixedPrice ? plan?.sharePrice : units * plan?.sharePrice;
  }
  return amount;
}

export function getBeneficiaryFDPercent(
  beneficiary,
  society,
  includeSoldUnits = false,
  soldUnits = 0
) {
  let units = beneficiary?.isCanceled
    ? beneficiary?.finalConsolidatedUnits
    : beneficiary?.sharesCount?.future;

  if (includeSoldUnits) {
    if (soldUnits) {
      units -= soldUnits;
    } else {
      units -= beneficiary?.soldUnits || 0;
    }
  }

  const fdPercent =
    (units * 100) /
    (society?.sharesCount?.actual +
      society?.sharesCount?.future +
      society?.sharesCount?.drafts);
  return fdPercent;
}

export function getVestedPercent(vestedUnits, society) {
  const percent =
    (vestedUnits * 100) /
    (society.sharesCount?.actual +
      society.sharesCount?.future +
      society.sharesCount?.drafts);
  return percent;
}

export function getSocietyValue(society) {
  if (!society) return null;

  if (society?.value) return society.value;

  if (!society?.societyValues?.length) return null;

  const societyValue = society?.societyValues.reduce((acc, current) => {
    if (current.date > acc.date) return current;
    return acc;
  }, society.societyValues[0]);
  return societyValue;
}

export function getSocietyValueAtDate(society, date) {
  if (!society) return null;
  if (!society?.societyValues.length) return null;

  const societyValue = society?.societyValues.reduce((acc, curr) => {
    if (
      !acc &&
      (new Date(curr.date) < new Date(date) ||
        new Date(curr.date).getTime() === new Date(date).getTime())
    )
      return curr;
    if (
      acc &&
      new Date(curr.date) > new Date(acc.date) &&
      (new Date(curr.date) < new Date(date) ||
        new Date(curr.date).getTime() === new Date(date).getTime())
    )
      return curr;
    return acc;
  }, null);

  return societyValue;
}

export function hasOutdatedSocietyValue(society) {
  if (
    !society ||
    !society.sharesCount ||
    !society.societyValues ||
    !society.societyValues?.length
  )
    return false;

  const lastSocietyValue = getSocietyValueAtDate(society, new Date());

  if (!lastSocietyValue?.isPriceByShare) return false;

  const sharesSocietyValue = Math.floor(
    lastSocietyValue.value / lastSocietyValue.shareValue
  );

  if (society.updateSocietyValueND) {
    return society?.sharesCount?.actual !== sharesSocietyValue;
  }

  if (society.updateSocietyValueFD) {
    const totalShares =
      society?.sharesCount?.actual +
      society?.sharesCount?.future +
      society?.sharesCount?.drafts;
    return totalShares !== sharesSocietyValue;
  }

  return false;
}

export function getPartnerOperations(partner, society) {
  if (!society || !partner) return null;
  const partnerOperationsInSociety = society.operations.filter((operation) =>
    partner.operations?.some((operationId) => operationId === operation['_id'])
  );
  return partnerOperationsInSociety;
}

export function countOperationShares(operation) {
  if (!operation) return null;
  const transactions = operation?.transactions?.filter(
    (transaction) =>
      transaction.transactionType !== transactionTypes.ADD_PARTNER &&
      transaction.transactionType !== transactionTypes.SELL
  );
  if (!transactions.length) return null;
  return transactions?.reduce(
    (acc, { shareFrom, shareTo }) => acc + (shareTo - shareFrom + 1),
    0
  );
}

// calcula el importe de las transacciones involucradas en la operacion
export function getOperationAmount(operation, society) {
  if (!operation) return null;

  if (operation.operationType === operationTypes.DIVIDENDS_DISTRIBUTION) {
    const amount = operation.dividends?.reduce(
      (acc, dividend) => acc + dividend.grossAmount,
      0
    );

    return amount;
  }

  // For the rest of operation types
  const transactions = operation?.transactions?.filter(
    (transaction) =>
      transaction.transactionType !== transactionTypes.ADD_PARTNER &&
      transaction.transactionType !== transactionTypes.SELL
  );

  if (!transactions.length) return null;

  const nominalValue = operation?.nominalValue || society?.nominalValue;

  const amount = transactions?.reduce((acc, transaction) => {
    let res = acc;
    const shareCount = transaction.shareTo - transaction.shareFrom + 1;
    if (
      transaction.transactionType === transactionTypes.BUY ||
      (transaction.transactionType === transactionTypes.CAPITAL_DECREASE &&
        transaction?.sharePrice)
    ) {
      res += shareCount * transaction.sharePrice;
    } else {
      res += (nominalValue + (transaction.sharePremium || 0)) * shareCount;
    }
    return res;
  }, 0);

  return amount;
}

export function getOperationById(operationId, society) {
  if (!society || !operationId) return null;

  const operation = society.operations.find(
    (operation) => operation['_id'] === operationId
  );

  return operation || null;
}

export function countOperationSharesByPartner(operation, partnerId) {
  if (!operation || !partnerId) return null;

  return operation?.transactions?.reduce(
    (acc, { shareFrom, shareTo, partner }) =>
      partner.id === partnerId && acc + (shareTo - shareFrom + 1),
    0
  );
}

export function getMovementsByPartner(operations, partner) {
  if (!operations || !partner) return null;

  const movements = [];

  operations?.forEach((operation) =>
    operation.movements.forEach((movement) =>
      movements.push({
        ...movement,
        operationType: operation.operationType,
        sharePremium: operation.sharePremium,
        currency: operation.currency,
        date: operation.date,
      })
    )
  );

  return movements.filter(
    (movement) => movement?.partner?.id === partner['_id']
  );
}

export function getTransactionsByPartner(operations, partner) {
  if (!operations || !partner) return null;
  const transactions = [];
  const excludedOperations = [
    operationTypes.ADD_BENEFICIARY,
    operationTypes.STOCK_OPTION,
    operationTypes.PHANTOM_SHARE,
    operationTypes.ADD_PARTNER,
    operationTypes.ADD_PARTNERS,
  ];
  const filteredOperations = operations?.filter(
    (operation) => !excludedOperations.includes(operation.operationType)
  );

  filteredOperations.forEach((operation) => {
    let alias = operation?.alias || '';
    if (
      operation?.operationType === operationTypes.HERITAGE ||
      operation?.operationType === operationTypes.DONATION
    ) {
      alias = operation?.operationType;
    }
    operation.transactions.forEach((transaction) =>
      transactions.push({
        ...transaction,
        nominalValue: operation.nominalValue || 0,
        currency: operation.currency,
        date: operation.date,
        operation: operation['_id'],
        documents: operation?.documents,
        comments: operation?.comments || '',
        alias,
      })
    );
  });
  return transactions.filter(
    (transaction) => transaction?.partner === partner['_id']
  );
}

export function getPartnersFromTransactions(transactions, society) {
  if (!transactions || !society) return null;

  const partners = [];
  transactions.forEach((transaction) => {
    const partner = society?.partners?.find(
      (partner) => partner?._id === transaction?.partner
    );
    if (partner) partners.push(partner);
  });

  return partners;
}

export function getPartnerIdFromSociety(email, society) {
  const currentPartner = society?.partners?.find(
    (partner) => partner?.id?.email === email
  );
  return { id: currentPartner?.id['_id'], cif: currentPartner?.cif };
}

export function getPartnerAmount(partnerShares, society) {
  if (!society) return null;
  const shares = society?.shares.filter(
    (share) =>
      share.isActive &&
      partnerShares?.some((shareId) => shareId === share['_id'])
  );
  const result = shares.reduce((acc, share) => {
    const shareCount = share.to - share.from + 1;
    let result = 0;
    if (share.sharePrice) result = share.sharePrice * shareCount;
    else
      result = (society.nominalValue + (share.sharePremium || 0)) * shareCount;
    return acc + result;
  }, 0);
  return result;
}

export function getPartnerNominalValue(partnerShares, society) {
  if (!society) return null;
  const shares = society?.shares.filter(
    (share) =>
      share.isActive &&
      partnerShares?.some((shareId) => shareId === share['_id'])
  );
  const result = shares.reduce((acc, share) => {
    const shareCount = share.to - share.from + 1;
    const result = (society?.nominalValue || 0) * shareCount;
    return acc + result;
  }, 0);
  return result;
}

export function getPartnerPremium(partnerShares, society) {
  if (!society) return null;
  const shares = society?.shares.filter(
    (share) =>
      share.isActive &&
      partnerShares?.some((shareId) => shareId === share['_id'])
  );
  const result = shares.reduce((acc, share) => {
    const shareCount = share.to - share.from + 1;
    const result = (share?.sharePremium || 0) * shareCount;
    return acc + result;
  }, 0);
  return result;
}

export function getPartnerInversion(partnerId, society) {
  if (!society) return null;
  const validOperations = [
    operationTypes.CONSTITUTION,
    operationTypes.SELL_PARTICIPATION,
    operationTypes.HERITAGE,
    operationTypes.DONATION,
    operationTypes.CAPITAL_INCREASE,
    operationTypes.CAPITAL_DECREASE,
    operationTypes.DIVIDENDS_DISTRIBUTION,
  ];

  const filteredOps = society?.operations.filter((op) =>
    validOperations.includes(op.operationType)
  );
  const inversion = filteredOps?.reduce((acc, operation) => {
    let result = acc;
    if (operation.operationType === operationTypes.DIVIDENDS_DISTRIBUTION) {
      const dividend = operation?.dividends?.find(
        (d) => d.partner === partnerId
      );
      if (dividend) {
        result += dividend?.amount || 0;
      }
    }
    operation.transactions.forEach((transaction) => {
      if (transaction.partner === partnerId) {
        const shareCount = transaction.shareTo - transaction.shareFrom + 1;
        const { transactionType } = transaction;
        switch (transactionType) {
          case transactionTypes.CONSTITUTION:
          case transactionTypes.CAPITAL_INCREASE:
            result -=
              (society?.nominalValue + (transaction?.sharePremium || 0)) *
              shareCount;
            break;
          case transactionTypes.CAPITAL_DECREASE:
            if (transaction?.sharePrice) {
              result += transaction?.sharePrice * shareCount;
            }
            break;
          case transactionTypes.BUY:
            result -= transaction?.sharePrice * shareCount;
            break;
          case transactionTypes.SELL:
            result += transaction?.sharePrice * shareCount;
            break;
          default:
            break;
        }
      }
    });
    return result;
  }, 0);
  return inversion;
}

export function getPartnerTotalSharesPremium(partnerId, society) {
  if (!society) return null;

  const partnerSharesPremium = society?.shares
    ?.filter((share) => share?.partner?.id === partnerId)
    .reduce((acc, share) => {
      let result = acc;
      result += share?.sharePremium || 0;
      return result;
    }, 0);

  return partnerSharesPremium;
}

export function getPartnerDividends(partnerId, society) {
  if (!partnerId || !society) return null;

  const operations = society?.operations
    ?.filter(
      (op) => op?.operationType === operationTypes.DIVIDENDS_DISTRIBUTION
    )
    ?.filter((op) => op?.dividends?.some((div) => div?.partner === partnerId));

  const partnerDividends = [];

  operations.forEach((op) => {
    const dividend = op?.dividends?.find((div) => div?.partner === partnerId);
    const totalAmount = op?.dividends?.reduce(
      (acc, curr) => acc + curr?.grossAmount,
      0
    );
    if (dividend) {
      partnerDividends.push({
        ...dividend,
        date: op?.date,
        totalAmount: totalAmount || 0,
        documents: op?.documents || [],
      });
    }
  });

  return partnerDividends;
}

export function getInvestorShares(investorCif, society) {
  if (!society || !investorCif) return 0;
  const draftsCount = society?.investors.reduce((acc, curr) => {
    if (curr?.cif && curr?.cif.toUpperCase() === investorCif.toUpperCase()) {
      return acc + curr?.sharesCount?.drafts;
    }
    return acc;
  }, 0);
  const plansCount = society?.beneficiaries.reduce((acc, curr) => {
    if (
      !curr?.isDraft &&
      curr?.cif &&
      curr?.cif.toUpperCase() === investorCif.toUpperCase()
    ) {
      const sharesCount = curr?.isCanceled
        ? curr?.finalConsolidatedUnits
        : curr?.sharesCount?.future;
      return acc + sharesCount;
    }
    return acc;
  }, 0);

  const partnerWithSameCif = society?.partners.find(
    (partner) => partner?.cif.toUpperCase() === investorCif.toUpperCase()
  );
  const partnerCount = partnerWithSameCif?.sharesCount?.actual || 0;
  return draftsCount + plansCount + partnerCount;
}

export function getPartnerActualShares(partnerId, society) {
  if (!society || !partnerId) return null;

  const currentPartner = society?.partners.find(
    (partner) => partner['_id'] === partnerId
  );

  return currentPartner?.sharesCount?.actual || 0;
}

export function getPartnerFutureShares(partnerId, society) {
  if (!society || !partnerId) return null;

  const currentPartner = society?.partners.find(
    (partner) => partner['_id'] === partnerId
  );

  return (
    currentPartner?.sharesCount?.actual +
      currentPartner?.sharesCount?.future +
      currentPartner?.sharesCount?.drafts || 0
  );
}

export function getSocietyInversion(society) {
  if (!society) return null;

  const shares = society.shares.filter((share) => share.isActive);
  return shares.reduce((acc, share) => {
    const shareCount = share.to - share.from + 1;

    let result;
    if (share.sharePrice) result = share.sharePrice * shareCount;
    else
      result = ((share.sharePremium || 0) + society.nominalValue) * shareCount;

    return acc + result;
  }, 0);
}

export function getSocietyTotalShares(society) {
  if (!society) return null;

  return (
    society.sharesCount?.actual +
    society.sharesCount?.future +
    society?.sharesCount?.drafts
  );
}

export function getSocietyActualShares(society) {
  if (!society) return null;

  return society?.sharesCount?.actual || 0;
}

export function getSocietyFutureShares(society) {
  if (!society) return null;

  return society?.sharesCount?.future;
}

export function getSocietyTotalSharesPostPlan(plan, society) {
  if (!plan || !society) return null;
  const planShares = society?.beneficiaries
    .filter((beneficiary) => beneficiary.plan === plan['_id'])
    .reduce((acc, curr) => {
      const shares = curr?.isCanceled
        ? acc + curr?.finalConsolidatedUnits
        : acc + curr?.sharesCount?.future;
      return shares;
    }, 0);
  const futurePostPlanShares =
    society?.sharesCount?.future - planShares + plan?.sharesTotal;
  return (
    society?.sharesCount?.actual +
    society?.sharesCount?.drafts +
    futurePostPlanShares
  );
}

export function getPlanPool(plan, society) {
  if (!plan || !society) return null;

  if (plan?.poolType === poolTypes.POST_MONEY) {
    const planShares = plan?.sharesTotal;
    const totalShares = getSocietyTotalSharesPostPlan(plan, society);

    const isInvalidShares = !totalShares || !planShares || totalShares === 0;
    if (isInvalidShares) {
      return 0;
    }

    return +bigDecimal.divide(planShares * 100, totalShares);
  }

  if (getSocietyActualShares(society) === 0) return 0;

  return +bigDecimal.divide(
    plan?.sharesTotal * 100,
    getSocietyActualShares(society)
  );
}

export function getBeneficiaryFromBeneficiaryId(society, beneficiaryId) {
  if (!beneficiaryId || !society) return null;

  return society?.beneficiaries?.find(
    (beneficiary) => beneficiary['_id'] === beneficiaryId
  );
}

export const getBeneficiaryUnits = (beneficiary) => {
  if (!beneficiary) return 0;

  return beneficiary?.isCanceled
    ? beneficiary?.finalConsolidatedUnits || 0
    : beneficiary?.sharesCount?.future || 0;
};

export function getBeneficiaryUnitsByPlanType(beneficiary) {
  if (!beneficiary) return { stocks: 0, phantoms: 0, warrants: 0, total: 0 };

  const totalUnits = beneficiary?.isCanceled
    ? beneficiary?.finalConsolidatedUnits || 0
    : beneficiary?.sharesCount?.future || 0;

  const stocks =
    beneficiary?.planType === operationTypes.STOCK_OPTION ? totalUnits : 0;
  const phantoms =
    beneficiary?.planType === operationTypes.PHANTOM_SHARE ? totalUnits : 0;
  const warrants =
    beneficiary?.planType === operationTypes.WARRANT ? totalUnits : 0;

  const total = stocks + phantoms + warrants;

  return { stocks, phantoms, warrants, total };
}

export function getSoldUnitsByPlan(
  tenderOffers,
  society,
  planId = '',
  includeRemovedUnits = true
) {
  if (!tenderOffers || !society) return {};

  const tenderOfferParticipants = tenderOffers
    ?.map((offer) => {
      const { participants } = offer;
      return participants;
    })
    .flat();

  const participantsFiltered = tenderOfferParticipants?.filter(
    (participant) =>
      participant.status === tenderOfferParticipantStatus.ACCEPTED &&
      participant.type === 'BENEFICIARY' &&
      (includeRemovedUnits || !participant.removedFromPlan)
  );

  const beneficiariesData = participantsFiltered?.map((participant) => {
    const currentBeneficiary = society?.beneficiaries.find(
      (beneficiary) => beneficiary?.['_id'] === participant?.id
    );

    return {
      plan: currentBeneficiary?.plan,
      soldUnits: participant.totalAccepted || 0,
    };
  });

  const beneficiariesDataObject = {};

  beneficiariesData?.forEach((beneficiary) => {
    const { plan, soldUnits } = beneficiary;
    beneficiariesDataObject[plan] = beneficiariesDataObject[plan]
      ? beneficiariesDataObject[plan] + soldUnits
      : soldUnits;
  });

  if (planId) {
    return beneficiariesDataObject[planId] || 0;
  }

  return {
    ...beneficiariesDataObject,
  };
}

export function getUsedSharesFromPlan(
  plan,
  society,
  tenderOffers,
  isDraft = false
) {
  if (!plan || !society) return null;

  const shares =
    society?.beneficiaries?.filter((beneficiary) => {
      const isValid = isDraft ? beneficiary?.isDraft : !beneficiary?.isDraft;
      return isValid && beneficiary.plan === plan['_id'];
    }) || [];

  const sharesFromBeneficiaries = shares.reduce((acc, beneficiary) => {
    const currentShares = beneficiary?.isCanceled
      ? beneficiary?.finalConsolidatedUnits
      : beneficiary?.sharesCount?.future;
    return acc + currentShares;
  }, 0);

  const sharesSold = tenderOffers
    ? getSoldUnitsByPlan(tenderOffers, society, plan['_id'])
    : 0;

  const totalShares = sharesFromBeneficiaries - sharesSold;

  return totalShares;
}

export function hasSocietyConstitutionOperation(society) {
  if (!society) return false;

  const hasConstitution = society?.operations?.find(
    (operation) => operation.operationType === operationTypes.CONSTITUTION
  );

  return !!hasConstitution;
}

export function isPartnerAlready(email, society) {
  if (!society || !email) return false;

  const result = society.partners?.find(
    (partner) => partner.email?.toLowerCase() === email.toLowerCase()
  );

  return !!result;
}

export function isBeneficiaryAlready(beneficiaryCif, planId, society) {
  if (!society || !beneficiaryCif) return false;

  const result = society.beneficiaries?.find(
    (beneficiary) =>
      beneficiary.cif?.toLowerCase() === beneficiaryCif.toLowerCase() &&
      beneficiary.plan === planId
  );

  return !!result;
}

export function isCifAlready(cif, society) {
  if (!society || !cif) return false;

  const result = society.partners?.find(
    (partner) => partner?.cif?.toLowerCase() === cif?.toLowerCase()
  );

  return !!result;
}

export function getUserBeneficiariesFromSociety(society, userId) {
  if (!society || !userId) return null;

  const userBeneficiaries =
    society?.beneficiaries?.filter(
      (beneficiary) =>
        beneficiary.user === userId || beneficiary?.otherUsers?.includes(userId)
    ) || [];

  const result = userBeneficiaries.map((beneficiary) => ({
    ...beneficiary,
    societyName: society.name,
  }));

  return result || null;
}

export function getUserInvestorsFromSociety(society, userId) {
  if (!society || !userId) return null;

  const userInvestors = society.investors.filter(
    (investor) => investor.user === userId
  );
  const result = userInvestors.map((investor) => ({
    ...investor,
    societyName: society.name,
  }));

  return result || null;
}

export function getDateSectionsInRange(minDate, maxDate, periodType) {
  const periods = [];
  const currentDate = new Date(minDate);
  let splitValue;
  let splitText;
  if (periodType === periodTypes.QUARTERLY.value) {
    splitValue = 3;
    splitText = i18n.t('Quarter');
  } else if (periodType === periodTypes.SEMIANUALLY.value) {
    splitValue = 6;
    splitText = i18n.t('Semester');
  } else if (periodType === periodTypes.ANUALLY.value) {
    splitValue = 12;
    splitText = i18n.t('Year');
  } else {
    return [];
  }
  currentDate.setMonth(
    Math.floor(currentDate.getMonth() / splitValue) * splitValue
  );
  while (
    (currentDate.getMonth() <= maxDate.getMonth() &&
      currentDate.getFullYear() <= maxDate.getFullYear()) ||
    currentDate.getFullYear() < maxDate.getFullYear()
  ) {
    const section =
      periodType === periodTypes.ANUALLY.value
        ? `${splitText} ${currentDate.getFullYear()}`
        : `${currentDate.getFullYear()} ${splitText} ${Math.ceil(
            (currentDate.getMonth() + 1) / splitValue
          )}`;

    periods.push(section);
    currentDate.setMonth(currentDate.getMonth() + splitValue);
  }
  return periods;
}

export function getDateSection(date, periodType) {
  const currentDate = new Date(date);
  let splitValue;
  let splitText;
  if (periodType === periodTypes.QUARTERLY.value) {
    splitValue = 3;
    splitText = i18n.t('Quarter');
  } else if (periodType === periodTypes.SEMIANUALLY.value) {
    splitValue = 6;
    splitText = i18n.t('Semester');
  } else if (periodType === periodTypes.ANUALLY.value) {
    splitValue = 1;
    splitText = i18n.t('Year');
  } else {
    return null;
  }
  currentDate.setMonth(
    Math.floor(currentDate.getMonth() / splitValue) * splitValue
  );
  return periodType === periodTypes.ANUALLY.value
    ? `${splitText} ${currentDate.getFullYear()}`
    : `${currentDate.getFullYear()} ${splitText} ${Math.ceil(
        (currentDate.getMonth() + 1) / splitValue
      )}`;
}

export function getPlansSummaryByPeriod(
  beneficiaries,
  plans,
  consolidationPeriods,
  tenderOffers,
  periodType
) {
  if (!plans.length || !beneficiaries.length) return null;
  const minDate = plans.reduce(
    (minDate, currentPlan) =>
      minDate > new Date(currentPlan?.startDate)
        ? new Date(currentPlan?.startDate)
        : minDate,
    new Date(plans[0]?.startDate)
  );

  const maxDate = beneficiaries.reduce((maxDate, beneficiary) => {
    const currentPlan = plans.find((plan) => plan['_id'] === beneficiary?.plan);
    const vestingPeriod =
      beneficiary?.customConditions &&
      'vestingPeriod' in beneficiary?.customConditions
        ? beneficiary?.customConditions?.vestingPeriod
        : currentPlan?.vestingPeriod;
    const endDate = new Date(beneficiary?.planStartDate);
    endDate.setMonth(endDate.getMonth() + Number(vestingPeriod));
    let updatedMaxDate = maxDate < endDate ? endDate : maxDate;
    const beneficiaryTenders = beneficiary.soldUnits || [];
    const tenders = tenderOffers.filter((tender) =>
      beneficiaryTenders.includes(tender['_id'])
    );
    tenders.forEach((tender) => {
      const participants = tender.participants.filter(
        (participant) => participant.id === beneficiary['_id']
      );
      participants.forEach((participant) => {
        const { type, status, operationDate } = participant;
        if (
          type === userTypes.BENEFICIARY &&
          status === tenderParticipantStatusTypes.ACCEPTED.value &&
          operationDate
        ) {
          if (updatedMaxDate < new Date(operationDate)) {
            updatedMaxDate = new Date(operationDate);
          }
        }
      });
    });
    return updatedMaxDate;
  }, new Date(plans[0]?.startDate));

  const sections = getDateSectionsInRange(minDate, maxDate, periodType);
  const periods = sections.reduce((acc, section) => {
    acc[section] = {
      availables: 0,
      assigned: 0,
      vested: 0,
      canceled: 0,
      acquired: 0,
    };
    return acc;
  }, {});

  // initialize summary object
  const summary = plans.reduce((acc, plan) => {
    acc[plan['_id']] = structuredClone(periods);
    return acc;
  }, {});
  // initialize availables count for each plan in the correspondent period for plan start date
  plans.forEach((plan) => {
    if (plan.startDate && plan.sharesTotal) {
      const section = getDateSection(new Date(plan.startDate), periodType);
      if (summary?.[plan['_id']]?.[section]) {
        summary[plan['_id']][section].availables = plan.sharesTotal;
      }
    }
  });

  const beneficiaryEventTypes = {
    ASSIGNMENT: 'ASSIGNMENT',
    VESTING: 'VESTING',
    CANCELATION: 'CANCELATION',
    TENDER: 'TENDER',
  };
  const allBeneficiaryEvents = plans.reduce((acc, plan) => {
    acc[plan['_id']] = [];
    return acc;
  }, {});
  beneficiaries.forEach((beneficiary) => {
    const currentPlan = plans.find((plan) => plan['_id'] === beneficiary?.plan);
    if (beneficiary.planStartDate) {
      if (allBeneficiaryEvents[currentPlan['_id']]) {
        allBeneficiaryEvents[currentPlan['_id']].push({
          date: new Date(beneficiary.planStartDate),
          units: beneficiary?.sharesCount?.future || 0,
          eventType: beneficiaryEventTypes.ASSIGNMENT,
        });
      }
    }
    if (beneficiary.isCanceled && beneficiary.cancelationDate) {
      if (allBeneficiaryEvents[currentPlan['_id']]) {
        allBeneficiaryEvents[currentPlan['_id']].push({
          date: new Date(beneficiary.cancelationDate),
          units: beneficiary?.finalConsolidatedUnits || 0,
          canceledUnits:
            beneficiary?.sharesCount?.future -
            beneficiary?.finalConsolidatedUnits,
          eventType: beneficiaryEventTypes.CANCELATION,
        });
      }
    }
    const beneficiaryConsolidationPeriods =
      consolidationPeriods[beneficiary['_id']] || [];
    beneficiaryConsolidationPeriods.forEach((period) => {
      const { date, vestedUnits } = period;
      if (allBeneficiaryEvents[currentPlan['_id']]) {
        allBeneficiaryEvents[currentPlan['_id']].push({
          date: new Date(date),
          units: vestedUnits || 0,
          eventType: beneficiaryEventTypes.VESTING,
        });
      }
    });

    const beneficiaryTenders = beneficiary.soldUnits || [];
    const tenders = tenderOffers.filter((tender) =>
      beneficiaryTenders.includes(tender['_id'])
    );
    tenders.forEach((tender) => {
      const participants = tender.participants.filter(
        (participant) => participant.id === beneficiary['_id']
      );
      participants.forEach((participant) => {
        const { type, totalAccepted, status, operationDate } = participant;
        if (
          type === userTypes.BENEFICIARY &&
          status === tenderParticipantStatusTypes.ACCEPTED.value &&
          totalAccepted &&
          operationDate
        ) {
          if (allBeneficiaryEvents[currentPlan['_id']]) {
            allBeneficiaryEvents[currentPlan['_id']].push({
              date: new Date(operationDate),
              units: totalAccepted || 0,
              eventType: beneficiaryEventTypes.TENDER,
            });
          }
        }
      });
    });
  });
  Object.entries(allBeneficiaryEvents).forEach(([planId, events]) => {
    const currentPlan = plans.find((plan) => plan['_id'] === planId);

    const visitedSections = sections.reduce((acc, section) => {
      acc[section] = false;
      return acc;
    }, {});
    if (currentPlan.startDate) {
      const startDateSection = getDateSection(
        new Date(currentPlan.startDate),
        periodType
      );
      visitedSections[startDateSection] = true;
    }

    const orderedEvents = events.sort((a, b) => a.date - b.date);
    let totalAssigned = 0;
    let totalCanceled = 0;
    let totalAcquired = 0;
    orderedEvents.forEach((event) => {
      const { date, units, canceledUnits, eventType } = event;

      if (eventType === beneficiaryEventTypes.ASSIGNMENT) {
        const dateSection = getDateSection(date, periodType);
        if (summary?.[planId]?.[dateSection]) {
          summary[planId][dateSection].assigned += units || 0;
          totalAssigned += units;
          summary[planId][dateSection].availables =
            currentPlan.sharesTotal -
            totalAssigned +
            totalCanceled +
            totalAcquired;
          visitedSections[dateSection] = true;
        }
      }
      if (eventType === beneficiaryEventTypes.CANCELATION) {
        const dateSection = getDateSection(date, periodType);
        if (summary?.[planId]?.[dateSection]) {
          summary[planId][dateSection].canceled += canceledUnits || 0;
          totalCanceled += canceledUnits;
          summary[planId][dateSection].availables =
            currentPlan.sharesTotal -
            totalAssigned +
            totalCanceled +
            totalAcquired;
          visitedSections[dateSection] = true;
        }
      }
      if (eventType === beneficiaryEventTypes.VESTING) {
        const dateSection = getDateSection(date, periodType);
        if (summary?.[planId]?.[dateSection]) {
          summary[planId][dateSection].vested += units || 0;
          summary[planId][dateSection].availables =
            currentPlan.sharesTotal -
            totalAssigned +
            totalCanceled +
            totalAcquired;
          visitedSections[dateSection] = true;
        }
      }
      if (eventType === beneficiaryEventTypes.TENDER) {
        const dateSection = getDateSection(date, periodType);
        if (summary?.[planId]?.[dateSection]) {
          summary[planId][dateSection].acquired += units || 0;
          totalAcquired += units || 0;
          summary[planId][dateSection].availables =
            currentPlan.sharesTotal -
            totalAssigned +
            totalCanceled +
            totalAcquired;
          visitedSections[dateSection] = true;
        }
      }
    });
    // Iterate over sections to set availables count in sections not visited
    let cursorCount = 0;
    const planSummary = summary[planId] || {};
    Object.entries(planSummary).forEach(([section, values]) => {
      if (visitedSections[section]) {
        cursorCount = values?.availables || 0;
      } else if (summary?.[planId]?.[section]) {
        summary[planId][section].availables = cursorCount;
      }
    });
  });
  return summary;
}

export function getBeneficiariesSummaryByPeriod(
  beneficiaries,
  plans,
  consolidationPeriods,
  tenderOffers,
  periodType
) {
  if (!plans.length || !beneficiaries.length) return null;
  const minDate = beneficiaries.reduce(
    (minDate, curr) =>
      minDate > new Date(curr?.planStartDate)
        ? new Date(curr?.planStartDate)
        : minDate,
    new Date(beneficiaries[0]?.planStartDate)
  );

  const maxDate = beneficiaries.reduce((maxDate, beneficiary) => {
    const currentPlan = plans.find((plan) => plan['_id'] === beneficiary?.plan);
    const vestingPeriod =
      beneficiary?.customConditions &&
      'vestingPeriod' in beneficiary?.customConditions
        ? beneficiary?.customConditions?.vestingPeriod
        : currentPlan?.vestingPeriod;
    const endDate = new Date(beneficiary?.planStartDate);
    endDate.setMonth(endDate.getMonth() + Number(vestingPeriod));
    let updatedMaxDate = maxDate < endDate ? endDate : maxDate;
    const beneficiaryTenders = beneficiary.soldUnits || [];
    const tenders = tenderOffers.filter((tender) =>
      beneficiaryTenders.includes(tender['_id'])
    );
    tenders.forEach((tender) => {
      const participants = tender.participants.filter(
        (participant) => participant.id === beneficiary['_id']
      );
      participants.forEach((participant) => {
        const { type, status, operationDate } = participant;
        if (
          type === userTypes.BENEFICIARY &&
          status === tenderParticipantStatusTypes.ACCEPTED.value &&
          operationDate
        ) {
          if (updatedMaxDate < new Date(operationDate)) {
            updatedMaxDate = new Date(operationDate);
          }
        }
      });
    });
    return updatedMaxDate;
  }, new Date(beneficiaries[0]?.planStartDate));

  const sections = getDateSectionsInRange(minDate, maxDate, periodType);
  const periods = sections.reduce((acc, section) => {
    acc[section] = {
      assigned: 0,
      vested: 0,
      totalVested: 0,
      canceled: 0,
      acquired: 0,
    };
    return acc;
  }, {});

  // initialize summary object
  const summary = beneficiaries.reduce((acc, beneficiary) => {
    acc[beneficiary['_id']] = structuredClone(periods);
    return acc;
  }, {});

  const beneficiaryEventTypes = {
    ASSIGNMENT: 'ASSIGNMENT',
    VESTING: 'VESTING',
    CANCELATION: 'CANCELATION',
    TENDER: 'TENDER',
  };
  const allBeneficiaryEvents = beneficiaries.reduce((acc, beneficiary) => {
    acc[beneficiary['_id']] = [];
    return acc;
  }, {});
  beneficiaries.forEach((beneficiary) => {
    if (beneficiary.planStartDate) {
      if (allBeneficiaryEvents[beneficiary['_id']]) {
        allBeneficiaryEvents[beneficiary['_id']].push({
          date: new Date(beneficiary.planStartDate),
          units: beneficiary?.sharesCount?.future || 0,
          eventType: beneficiaryEventTypes.ASSIGNMENT,
        });
      }
    }
    if (beneficiary.isCanceled && beneficiary.cancelationDate) {
      if (allBeneficiaryEvents[beneficiary['_id']]) {
        allBeneficiaryEvents[beneficiary['_id']].push({
          date: new Date(beneficiary.cancelationDate),
          units: beneficiary?.finalConsolidatedUnits || 0,
          canceledUnits:
            beneficiary?.sharesCount?.future -
            beneficiary?.finalConsolidatedUnits,
          eventType: beneficiaryEventTypes.CANCELATION,
        });
      }
    }
    const beneficiaryConsolidationPeriods =
      consolidationPeriods[beneficiary['_id']] || [];
    beneficiaryConsolidationPeriods.forEach((period) => {
      const { date, vestedUnits } = period;
      if (allBeneficiaryEvents[beneficiary['_id']]) {
        allBeneficiaryEvents[beneficiary['_id']].push({
          date: new Date(date),
          units: vestedUnits || 0,
          eventType: beneficiaryEventTypes.VESTING,
        });
      }
    });

    const beneficiaryTenders = beneficiary.soldUnits || [];
    const tenders = tenderOffers.filter((tender) =>
      beneficiaryTenders.includes(tender['_id'])
    );
    tenders.forEach((tender) => {
      const participants = tender.participants.filter(
        (participant) => participant.id === beneficiary['_id']
      );
      participants.forEach((participant) => {
        const { type, totalAccepted, status, operationDate } = participant;
        if (
          type === userTypes.BENEFICIARY &&
          status === tenderParticipantStatusTypes.ACCEPTED.value &&
          totalAccepted &&
          operationDate
        ) {
          if (allBeneficiaryEvents[beneficiary['_id']]) {
            allBeneficiaryEvents[beneficiary['_id']].push({
              date: new Date(operationDate),
              units: totalAccepted || 0,
              eventType: beneficiaryEventTypes.TENDER,
            });
          }
        }
      });
    });
  });

  beneficiaries.forEach((beneficiary) => {
    const visitedSections = sections.reduce((acc, section) => {
      acc[section] = false;
      return acc;
    }, {});

    const events = allBeneficiaryEvents?.[beneficiary['_id']] || [];
    const orderedEvents = events.sort((a, b) => a.date - b.date);
    let totalVested = 0;
    orderedEvents.forEach((event) => {
      const { date, units, canceledUnits, eventType } = event;

      if (eventType === beneficiaryEventTypes.ASSIGNMENT) {
        const dateSection = getDateSection(date, periodType);
        if (summary?.[beneficiary['_id']]?.[dateSection]) {
          summary[beneficiary['_id']][dateSection].assigned = units || 0;
          visitedSections[dateSection] = true;
        }
      }
      if (eventType === beneficiaryEventTypes.CANCELATION) {
        const dateSection = getDateSection(date, periodType);
        if (summary?.[beneficiary['_id']]?.[dateSection]) {
          summary[beneficiary['_id']][dateSection].canceled =
            canceledUnits || 0;
          if (totalVested < units) {
            const cancelationVested = units - totalVested;
            summary[beneficiary['_id']][dateSection].vested +=
              cancelationVested || 0;
          }
          summary[beneficiary['_id']][dateSection].totalVested = units || 0;
          totalVested += units;
          visitedSections[dateSection] = true;
        }
      }
      if (eventType === beneficiaryEventTypes.VESTING) {
        const dateSection = getDateSection(date, periodType);
        if (summary?.[beneficiary['_id']]?.[dateSection]) {
          summary[beneficiary['_id']][dateSection].vested += units || 0;
          totalVested += units;
          summary[beneficiary['_id']][dateSection].totalVested =
            totalVested || 0;
          visitedSections[dateSection] = true;
        }
      }
      if (eventType === beneficiaryEventTypes.TENDER) {
        const dateSection = getDateSection(date, periodType);
        if (summary?.[beneficiary['_id']]?.[dateSection]) {
          summary[beneficiary['_id']][dateSection].acquired += units || 0;
          visitedSections[dateSection] = true;
        }
      }
    });
    // Iterate over sections to set availables count in sections not visited
    let cursorCount = 0;
    const planSummary = summary[beneficiary['_id']] || {};
    Object.entries(planSummary).forEach(([section, values]) => {
      if (visitedSections[section]) {
        cursorCount = values?.totalVested || 0;
      } else if (summary?.[beneficiary['_id']]?.[section]) {
        summary[beneficiary['_id']][section].totalVested = cursorCount;
      }
    });
  });
  return summary;
}

export function getVestedUnits(beneficiary, plan, date = Date.now()) {
  if (!beneficiary || !plan) return 0;
  if (beneficiary?.isCanceled) return beneficiary?.finalConsolidatedUnits;

  const consolidationPeriods = getConsolidationPeriods(beneficiary, plan);
  const closestCancelationPeriod = consolidationPeriods?.reduce(
    (acc, period) => {
      if (new Date(period?.date).getTime() <= new Date(date).getTime()) {
        if (!acc || new Date(acc?.date) < new Date(period?.date)) {
          return period;
        }
      }
      return acc;
    },
    null
  );
  if (closestCancelationPeriod) {
    return closestCancelationPeriod?.totalVestedUnits || 0;
  }
  const percent = calculateConsolidationPercent(date, beneficiary, plan);
  const vested = Math.floor((percent * beneficiary?.sharesCount?.future) / 100);
  return vested;
}

export function getInvestors(investor, society) {
  if (!investor || !society) return null;
  if (!investor.cif) return [investor];
  const allInvestors = society?.investors.filter(
    (i) => i.cif && i.cif === investor.cif
  );
  return allInvestors;
}

export function getInvestorNDPercent(investor, society) {
  if (!investor || !society) return null;

  const partnerWithSameCif = society?.partners.find(
    (partner) => partner.cif === investor?.cif
  );
  const investorCount =
    investor?.sharesCount?.drafts +
    (partnerWithSameCif?.sharesCount?.actual || 0);

  const draftShares = society.investors
    .filter((i) => i.draft === investor.draft)
    .reduce((acc, curr) => acc + (curr?.sharesCount?.drafts || 0), 0);
  const societyShares = society?.sharesCount?.actual + draftShares;
  return (investorCount * 100) / societyShares;
}

export function getInvestorTotalNDPercent(investor, society) {
  if (!investor || !society) return null;
  const draftShares = society.investors
    .filter((i) => i.draft === investor.draft)
    .reduce((acc, curr) => acc + (curr?.sharesCount?.drafts || 0), 0);
  const societyShares = society?.sharesCount?.actual + draftShares;
  const investorShares = investor?.sharesCount?.drafts || 0;
  return (investorShares * 100) / societyShares;
}

export function getInvestorFDPercent(investor, society) {
  if (!investor || !society) return null;
  const investorCount = investor?.cif
    ? getInvestorShares(investor.cif, society)
    : investor?.sharesCount?.drafts;
  const societyShares =
    society.sharesCount?.actual +
    (society.sharesCount?.future || 0) +
    (society.sharesCount?.drafts || 0);
  return (investorCount * 100) / societyShares;
}

export function getInvestorFDPotential(investor, society, plans) {
  if (!investor || !society || !plans) return null;

  const plansShares = plans
    .filter((plan) => !plan?.isDraft)
    .reduce((acc, plan) => acc + plan?.sharesTotal, 0);

  const investorCount = investor?.cif
    ? getInvestorShares(investor.cif, society)
    : investor?.sharesCount?.drafts;
  const societyShares =
    society.sharesCount?.actual +
    (society.sharesCount?.drafts || 0) +
    plansShares;
  return (investorCount * 100) / societyShares;
}

export function getInvestorTotalContribution(investor, society) {
  if (!investor) return 0;
  if (!investor.cif) return investor?.realContribution || investor.contribution;
  const totalContribution = society.investors
    .filter((i) => i.cif && i.cif === investor.cif)
    .reduce(
      (acc, curr) => acc + (curr?.realContribution || curr.contribution),
      0
    );
  return totalContribution;
}

export function getInvestorTotalNewShares(investor, society) {
  if (!investor) return 0;
  if (!investor.cif) return investor?.sharesCount?.drafts;
  const totalNewShares = society.investors
    .filter((i) => i.cif && i.cif === investor.cif)
    .reduce((acc, curr) => acc + (curr?.sharesCount?.drafts || 0), 0);
  return totalNewShares;
}

export function getPartnerNDPercent(partner, society) {
  if (!partner || !society) return null;

  const partnerActualShares = partner.sharesCount?.actual || 0;
  const societyActualShares = society.sharesCount?.actual || 0;

  const NDPercent = (partnerActualShares * 100) / societyActualShares;

  return NDPercent || 0;
}

export function getPartnerFDPercent(partner, society) {
  if (!partner || !society) return null;
  if (partner.sharesCount?.computedFD) {
    return partner.sharesCount?.computedFD;
  }
  const futureShares =
    partner.sharesCount?.actual +
    (partner.sharesCount?.future || 0) +
    (partner.sharesCount?.drafts || 0);
  const societyShares =
    society.sharesCount?.actual +
    (society.sharesCount?.future || 0) +
    (society.sharesCount?.drafts || 0);

  const FDPercent = (futureShares * 100) / societyShares;

  return FDPercent || 0;
}

export function getPartnerPotentialFD(partner, society, plans) {
  if (!partner || !society || !plans) return null;
  if (partner.sharesCount?.computedPotentialFD) {
    return partner.sharesCount?.computedPotentialFD;
  }
  const plansShares = plans
    .filter((plan) => !plan?.isDraft)
    .reduce((acc, plan) => acc + plan?.sharesTotal, 0);

  const futureShares =
    partner.sharesCount?.actual +
    (partner.sharesCount?.future || 0) +
    (partner.sharesCount?.drafts || 0);
  const societyShares =
    society.sharesCount?.actual +
    (society.sharesCount?.drafts || 0) +
    plansShares;

  const potentialFD = (futureShares * 100) / societyShares;

  return potentialFD || 0;
}

export function getCategoryNDPercent(category, society) {
  if (!category || !society) return null;

  const partners = society.partners.filter(
    (partner) => partner?.category === category?.['_id']
  );
  const actualShares = partners.reduce(
    (acc, partner) => acc + (partner?.sharesCount?.actual || 0),
    0
  );
  const partnerShares = society.sharesCount?.actual || 0;

  const NDPercent = (actualShares * 100) / partnerShares;

  return NDPercent || 0;
}

export function getCategoryFDPercent(category, society) {
  if (!category || !society) return null;
  const partners = society.partners.filter(
    (partner) => partner?.category === category?.['_id']
  );
  const futureShares = partners.reduce(
    (acc, partner) =>
      acc +
      (partner.sharesCount?.actual || 0) +
      (partner.sharesCount?.future || 0) +
      (partner.sharesCount?.drafts || 0),
    0
  );
  const societyShares =
    society.sharesCount?.actual +
    (society.sharesCount?.future || 0) +
    (society.sharesCount?.drafts || 0);

  const FDPercent = (futureShares * 100) / societyShares;

  return FDPercent || 0;
}

export function getCategoryPotentialFD(category, society, plans) {
  if (!category || !society || !plans) return null;
  const plansShares = plans
    .filter((plan) => !plan?.isDraft)
    .reduce((acc, plan) => acc + plan?.sharesTotal, 0);

  const partners = society.partners.filter(
    (partner) => partner?.category === category?.['_id']
  );
  const futureShares = partners.reduce(
    (acc, partner) =>
      acc +
      (partner.sharesCount?.actual || 0) +
      (partner.sharesCount?.future || 0) +
      (partner.sharesCount?.drafts || 0),
    0
  );

  const societyShares =
    society.sharesCount?.actual +
    (society.sharesCount?.drafts || 0) +
    plansShares;

  const potentialFD = (futureShares * 100) / societyShares;

  return potentialFD || 0;
}

export function getPartnerShares(partnerId, society) {
  if (!partnerId) return null;
  const shares = society?.shares
    .filter((share) => share.isActive && share.partner === partnerId)
    .sort((a, b) => a.from - b.from);
  return shares;
}

export function getPartnerSharesDetails(partner, society) {
  const shares = society?.shares
    ?.filter((share) => share.isActive && share.partner === partner['_id'])
    ?.map((filteredShare) => ({
      nominalValue: society?.nominalValue,
      from: filteredShare.from,
      to: filteredShare.to,
      sharePremium: filteredShare.sharePremium,
      shareClass: filteredShare.shareClass?.name || '-',
      tag: filteredShare?.tag || '',
    }));

  return shares || [];
}

export function isPartnerActiveByShares(partner, society) {
  return society?.shares?.some(
    (share) => share?.isActive && share.partner === partner?.['_id']
  );
}

export function getTotalContribution(drafts) {
  if (!drafts) return 0;
  const totals = drafts.reduce(
    (acc, draft) => {
      const currentTotals = draft?.draftPartners.reduce(
        (acc, partner) => {
          acc.contribution += partner?.totalContribution || 0;
          acc.shares += partner?.newShares || 0;
          return acc;
        },
        {
          contribution: 0,
          shares: 0,
        }
      );
      acc.totalContribution += currentTotals.contribution;
      acc.totalShares += currentTotals.shares;
      return acc;
    },
    { totalContribution: 0, totalShares: 0 }
  );
  return totals;
}

export function getTimelinePartnerShares(society) {
  const partnersCount = society?.partners?.reduce((acc, partner) => {
    acc[partner['_id']] = 0;
    return acc;
  }, {});

  const includedOperations = [
    operationTypes.CONSTITUTION,
    operationTypes.CAPITAL_INCREASE,
    operationTypes.CAPITAL_DECREASE,
    operationTypes.SPLIT,
    operationTypes.SELL_PARTICIPATION,
    operationTypes.HERITAGE,
    operationTypes.DONATION,
  ];
  const filteredOperations = society?.operations
    ?.filter((operation) =>
      includedOperations.includes(operation.operationType)
    )
    ?.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));

  let sharesCount = 0;
  const result = filteredOperations?.reduce(
    (acc, operation) => {
      if (operation.operationType === operationTypes.SPLIT) {
        Object.entries(partnersCount)?.forEach(([id]) => {
          partnersCount[id] = 0;
        });
        sharesCount = operation?.transactions?.reduce((acc, transaction) => {
          const currentCount = transaction.shareTo - transaction.shareFrom + 1;
          partnersCount[transaction.partner] += currentCount;
          return currentCount + acc;
        }, 0);
      } else {
        for (let i = 0; i < operation?.transactions?.length; i += 1) {
          const transaction = operation?.transactions[i];
          const transactionCount =
            transaction.shareTo - transaction.shareFrom + 1;
          if (transaction.transactionType === transactionTypes.SELL) {
            partnersCount[transaction.partner] -= transactionCount;
          } else if (
            transaction.transactionType === transactionTypes.CAPITAL_DECREASE
          ) {
            partnersCount[transaction.partner] -= transactionCount;
            sharesCount -= transactionCount;
          } else if (transaction.transactionType === transactionTypes.BUY) {
            partnersCount[transaction.partner] += transactionCount;
          } else {
            partnersCount[transaction.partner] += transactionCount;
            sharesCount += transactionCount;
          }
        }
      }
      acc.timelinePrices[operation['_id']] = getOperationAmount(
        operation,
        society
      );
      acc.timelineShares[operation['_id']] = sharesCount;
      acc.timelinePartners[operation['_id']] = Object.keys(
        partnersCount
      ).filter((key) => partnersCount[key] > 0).length;
      return acc;
    },
    {
      timelinePrices: {},
      timelineShares: {},
      timelinePartners: {},
    }
  );

  return result;
}

export function getDecimalScale(societyValue) {
  if (!societyValue) return null;
  let minScale = 2;
  let minValue = 0.01;
  while ((minValue * societyValue) / 100 > 1) {
    minScale += 1;
    minValue /= 10;
  }
  return minScale;
}

export function kFormatter(num) {
  if (Math.abs(num) < 999) {
    return Math.sign(num) * Math.abs(num);
  }
  if (Math.abs(num) < 999999) {
    return `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(2)}K`;
  }
  return `${Math.sign(num) * (Math.abs(num) / 1000000).toFixed(2)}M`;
}

export function groupBy(collection, property) {
  let i = 0;
  let val;
  let index;
  const values = [];
  const result = [];
  for (; i < collection.length; i += 1) {
    val = collection[i][property];
    index = values.indexOf(val);
    if (index > -1) result[index].push(collection[i]);
    else {
      values.push(val);
      result.push([collection[i]]);
    }
  }
  return result;
}

export function getSocietyOperations(society) {
  const operations =
    society?.operations?.sort((a, b) =>
      new Date(a.date) > new Date(b.date) ? -1 : 1
    ) || [];
  return operations;
}

function isDirectorActive(director) {
  const currStatusTypes = statusTypes(i18n);
  return (
    director?.directorStatus === currStatusTypes.var.ACTIVE ||
    (!director?.directorStatus &&
      (!director.endDate || new Date(director.endDate) > new Date()))
  );
}

export function getSocietyPartnersWithShares(society) {
  const partners =
    society?.partners?.filter((partner) => partner?.sharesCount?.actual > 0) ||
    [];
  return partners;
}

export function getSocietyEmails(society, memberType) {
  if (!society || !memberType) return [];

  switch (memberType) {
    case presetRecipients.PARTNERS:
      return (
        society?.partners
          ?.filter((partner) => partner?.sharesCount?.actual > 0)
          ?.map((partner) => ({
            name: partner?.name || '',
            email: partner?.email || '',
          })) || []
      );

    case presetRecipients.BENEFICIARIES:
      return (
        society?.beneficiaries?.map((beneficiary) => ({
          name: beneficiary?.name || '',
          email: beneficiary?.email || '',
        })) || []
      );

    case presetRecipients.INVESTORS:
      return (
        society?.drafts
          ?.filter(
            ({ draftType }) => draftType === draftTypes.DRAFT_CONVERTIBLE_NOTE
          )
          .map(({ draftPartners }) => JSON.parse(draftPartners))
          .flat()
          .map(({ societyPartnerId }) => societyPartnerId)
          .map((partnerId) =>
            society?.partners.find((partner) => partner['_id'] === partnerId)
          )
          .map((investor) => ({
            name: investor?.name || '',
            email: investor?.email || '',
          })) || []
      );

    case presetRecipients.DIRECTORS:
      return (
        society?.directors
          ?.filter((director) => isDirectorActive(director))
          .map((director) => ({
            name: director?.socialDenomination || '',
            email: director?.email || '',
          })) || []
      );

    default:
      return [];
  }
}

export function getFullAddress(address) {
  if (!address || typeof address !== 'object') return '';

  return Object.values(address)
    .filter((value) => value !== '')
    .join(', ');
}

export function getVoteInstructions(board) {
  if (!board?.delegation?.partner?.delegationVote) return ' ';
  const { instructions, instructionType } =
    board?.delegation?.partner?.delegationVote || {};
  let text = ' ';
  switch (instructionType) {
    case instructionTypes.ALL_YES.value:
      text =
        'b) Vote favorablemente sobre todos los asuntos comprendidos en el Orden del Día de la referida Junta.<br />';
      break;
    case instructionTypes.ALL_NO.value:
      text =
        'b) Vote en contra sobre todos los asuntos comprendidos en el Orden del Día de la referida Junta.<br />';
      break;
    case instructionTypes.ALL_ABS.value:
      text =
        'b) Se abstenga en la votación de todos los asuntos comprendidos en el Orden del Día de la referida Junta.<br />';
      break;
    case instructionTypes.POINT_BY_POINT.value:
      text =
        'b) Vote sobre todos los asuntos comprendidos en el Orden del Día de la referida Junta siguiendo las presentes instrucciones:<br />';
      if (instructions?.length) {
        const instructionsConcat = instructions
          .map((instruction) => {
            const currentOrder = board?.orders?.find(
              (order) => order['_id'] === instruction.order
            );
            return `${currentOrder?.subject}: ${
              voteValues?.[instruction.vote]?.text
            }`;
          })
          .join('<br />');
        text = text.concat('<br />', instructionsConcat);
      }
      break;
    default:
      break;
  }
  return text;
}

export function generateEmail(society, partnerNumber) {
  const { name } = society;
  const senderDomain = getSocietySenderDomain(society);
  const currentNumber = partnerNumber || society?.currentPartner + 1;
  const formattedNumber = currentNumber.toString().padStart(4, '0');
  const formattedName = name?.replace(/[^a-zA-Z0-9]/g, '');

  const email = `${formattedName.toLowerCase()}${formattedNumber}${senderDomain}`;
  return email;
}

export const getBeneficiariesWithSameCIF = (
  cif,
  society,
  includeDraft = false
) => {
  if (!cif || !society?.beneficiaries) return [];

  const normalizedCIF = cif.toUpperCase();

  const beneficiaries = society.beneficiaries.filter((beneficiary) => {
    const beneficiaryCIF = beneficiary.cif.toUpperCase();
    return (
      beneficiaryCIF === normalizedCIF && (includeDraft || !beneficiary.isDraft)
    );
  });

  return beneficiaries;
};

export const getTenderOfferStatus = (society, tenderOffer) => {
  if (!society || !tenderOffer)
    return {
      totalExecuted: 0,
      totalAccepted: 0,
      totalRejected: 0,
    };

  const totals = {};

  tenderOffer.participants.forEach((participant) => {
    if (totals[participant.status]) {
      totals[participant.status] += participant.totalAccepted;
    } else {
      totals[participant.status] = participant.totalAccepted;
    }
  });

  const totalAccepted = totals.ACCEPTED || 0;
  const totalExecuted = totals.EXECUTED || 0;
  const totalRejected = totals.REJECTED || 0;

  return {
    totalExecuted,
    totalAccepted,
    totalRejected,
  };
};

export const getBeneficiaryPercentVested = (
  beneficiary,
  planData,
  calculationDate
) => calculateConsolidationPercent(calculationDate, beneficiary, planData);

export const getBeneficiaryUnitsVested = (
  beneficiary,
  planData,
  calculationDate
) => {
  if (beneficiary?.isCanceled) {
    return beneficiary.finalConsolidatedUnits;
  }

  const units = beneficiary?.sharesCount?.future || 0;
  const percent = calculateConsolidationPercent(
    calculationDate,
    beneficiary,
    planData
  );
  return Math.floor((units * percent) / 100);
};

export const getStocks = (beneficiary) =>
  Math.floor(
    (getBeneficiaryPercentVested(
      beneficiary,
      beneficiary.planData,
      beneficiary.calculationDate
    ) *
      getBeneficiaryUnitsByPlanType(beneficiary).stocks) /
      100
  );

export const getPhantoms = (beneficiary) =>
  Math.floor(
    (getBeneficiaryPercentVested(
      beneficiary,
      beneficiary.planData,
      beneficiary.calculationDate
    ) *
      getBeneficiaryUnitsByPlanType(beneficiary).phantoms) /
      100
  );

export const getAvailableStocks = (beneficiary, tenderOffer) => {
  let currentStocks =
    (getBeneficiaryUnitsVested(
      beneficiary,
      beneficiary.planData,
      beneficiary.calculationDate
    ) *
      tenderOffer.maxPercentUnvested) /
    100;

  if (!tenderOffer.sellAll) {
    const stocksMaxAllowed = tenderOffer.maxStocks
      ? Math.min(currentStocks, tenderOffer.maxStocks)
      : currentStocks;
    const stocksPercentAllowed = Math.floor(
      (currentStocks * tenderOffer.maxPercentStocks) / 100
    );
    const totalStocksAllowed = Math.max(stocksMaxAllowed, stocksPercentAllowed);
    const priceTotalStocks = totalStocksAllowed * tenderOffer.totalPriceByShare;

    if (priceTotalStocks < tenderOffer.maxAmount) {
      currentStocks = totalStocksAllowed;
    } else {
      currentStocks = Math.floor(
        tenderOffer.maxAmount / tenderOffer.totalPriceByShare
      );
    }
  }

  return Math.floor(currentStocks);
};

export const getAvailablePhantoms = (beneficiary, tenderOffer) => {
  let currentPhantoms =
    (getBeneficiaryUnitsVested(
      beneficiary,
      beneficiary.planData,
      beneficiary.calculationDate
    ) *
      tenderOffer.maxPercentUnvested) /
    100;

  if (!tenderOffer.sellAll) {
    const phantomsMaxAllowed = tenderOffer.maxPhantoms
      ? Math.min(currentPhantoms, tenderOffer.maxPhantoms)
      : currentPhantoms;
    const phantomsPercentAllowed = Math.floor(
      (currentPhantoms * tenderOffer.maxPercentPhantoms) / 100
    );
    const totalPhantomsAllowed = Math.max(
      phantomsMaxAllowed,
      phantomsPercentAllowed
    );
    const priceTotalPhantoms =
      totalPhantomsAllowed * tenderOffer.totalPriceByShare;

    if (priceTotalPhantoms < tenderOffer.maxAmount) {
      currentPhantoms = totalPhantomsAllowed;
    } else {
      currentPhantoms = Math.floor(
        tenderOffer.maxAmount / tenderOffer.totalPriceByShare
      );
    }
  }

  return Math.floor(currentPhantoms);
};

export const getAvailableShares = (partner, tenderOffer) => {
  let currentShares = partner.sharesCount.actual;

  if (!tenderOffer.sellAll) {
    const sharesMaxAllowed = tenderOffer.maxUnits
      ? Math.min(currentShares, tenderOffer.maxUnits)
      : currentShares;
    const sharesPercentAllowed =
      (currentShares * tenderOffer.maxPercentUnits) / 100;
    const totalSharesAllowed = Math.max(sharesMaxAllowed, sharesPercentAllowed);
    const priceTotalShares = totalSharesAllowed * tenderOffer.totalPriceByShare;

    if (priceTotalShares < tenderOffer.maxAmount) {
      currentShares = totalSharesAllowed;
    } else {
      currentShares = Math.floor(
        tenderOffer.maxAmount / tenderOffer.totalPriceByShare
      );
    }
  }

  return Math.floor(currentShares);
};

export const getSoldUnitsAtDate = (beneficiary, tenderOffers, date) => {
  if (!beneficiary || !tenderOffers || !date) return 0;

  const tenderOffersArray = Array.isArray(tenderOffers)
    ? tenderOffers
    : [tenderOffers];

  const soldUnits = tenderOffersArray.reduce((acc, tenderOffer) => {
    const tenderOfferDate = new Date(
      tenderOffer.calculationDate || tenderOffer.startDate
    );
    const beneficiaryDate = new Date(date);

    if (tenderOfferDate <= beneficiaryDate) {
      const soldUnitsAtDate = tenderOffer.participants?.reduce(
        (acc, participant) => {
          if (
            participant.id === beneficiary._id &&
            participant.status === tenderParticipantStatusTypes.ACCEPTED.value
          ) {
            return acc + participant.totalAccepted;
          }
          return acc;
        },
        0
      );
      return acc + soldUnitsAtDate;
    }
    return acc;
  }, 0);

  return soldUnits;
};

export const getTotalUnitsFromEligibles = (eligiblesList) => {
  if (!eligiblesList.length) return 0;

  const totalUnits = eligiblesList.reduce(
    (acc, eligible) => acc + eligible.units,
    0
  );

  return totalUnits;
};

const parsePartner = (partner, tenderOffer, society) => ({
  id: partner._id,
  name: partner.name,
  email: partner.email,
  cif: partner?.cif || '',
  employeeId: partner?.employeeNumber || '',
  taxAddress: getFullAddress(partner?.taxAddress),
  bankAccountNumber: partner?.bankAccountNumber || '',
  units: getAvailableShares(partner, tenderOffer),
  originalUnits: partner.sharesCount.actual,
  shares: getAvailableShares(partner, tenderOffer),
  stocks: 0,
  phantoms: 0,
  NDPercent: getPartnerNDPercent(partner, society),
  FDPercent: getPartnerFDPercent(partner, society),
  retentionPercent: tenderOffer?.retentionPercent || 0,
  acquisitionPrice: getPartnerAmount(partner.shares, society),
  exercisePrice: 0,
  type: 'PARTNER',
});

const parseBeneficiary = (beneficiary, tenderOffer, society) => ({
  id: beneficiary._id,
  name: beneficiary.name,
  email: beneficiary.email,
  cif: beneficiary?.cif || '',
  employeeId: beneficiary?.employeeNumber || '',
  taxAddress: getFullAddress(beneficiary?.taxAddress),
  bankAccountNumber: beneficiary?.bankAccountNumber || '',
  units:
    beneficiary.planType === 'STOCK_OPTION'
      ? getAvailableStocks(beneficiary, tenderOffer)
      : getAvailablePhantoms(beneficiary, tenderOffer),
  shares: 0,
  stocks: getAvailableStocks(beneficiary, tenderOffer),
  phantoms: getAvailablePhantoms(beneficiary, tenderOffer),
  originalUnits:
    getBeneficiaryUnitsVested(
      beneficiary,
      beneficiary.planData,
      beneficiary.calculationDate
    ) || 0,
  originalStocks:
    beneficiary.planType === 'STOCK_OPTION'
      ? getBeneficiaryUnitsVested(
          beneficiary,
          beneficiary.planData,
          beneficiary.calculationDate
        )
      : 0,
  originalPhantoms:
    beneficiary.planType === 'PHANTOM_SHARE'
      ? getBeneficiaryUnitsVested(
          beneficiary,
          beneficiary.planData,
          beneficiary.calculationDate
        )
      : 0,
  vestedPercent: getBeneficiaryPercentVested(
    beneficiary,
    beneficiary.planData,
    beneficiary.calculationDate
  ),
  NDPercent: 0,
  FDPercent: getBeneficiaryFDPercent(beneficiary, society),
  retentionPercent: tenderOffer?.retentionPercent || 0,
  acquisitionPrice: 0,
  exercisePrice: beneficiary?.amount || 0,
  strikePrice:
    beneficiary?.customConditions?.sharePrice ||
    beneficiary?.planData?.sharePrice ||
    0,
  planType: beneficiary?.planType,
  planStartDate: beneficiary?.planStartDate || '',
  type: 'BENEFICIARY',
});

const parseEligibles = (eligibles, tenderOffer, society, model) => {
  if (!eligibles || !society || !model) return [];

  let parsedData = [];

  switch (model) {
    case 'PARTNER':
      parsedData = eligibles?.map((partner) =>
        parsePartner(partner, tenderOffer, society)
      );
      break;

    case 'BENEFICIARY':
      parsedData = eligibles?.map((beneficiary) =>
        parseBeneficiary(beneficiary, tenderOffer, society)
      );
      break;

    case 'MIXED':
      parsedData = eligibles?.map((eligible) =>
        eligible.plan
          ? parseBeneficiary(eligible, tenderOffer, society)
          : parsePartner(eligible, tenderOffer, society)
      );
      break;

    default:
      break;
  }

  return parsedData;
};

export const getEligiblesData = (
  availableOptions,
  actualTenderOffer,
  actualSociety,
  plans
) => {
  if (!actualTenderOffer || !availableOptions) return;

  const { partners, beneficiaries } = actualSociety;

  const selectedGroup =
    actualTenderOffer?.eligiblesSubgroup && actualTenderOffer?.eligiblesType
      ? availableOptions[actualTenderOffer?.eligiblesType][
          actualTenderOffer?.eligiblesSubgroup
        ]
      : [];

  let filtered;
  let filteredWithPlanData;
  let eligiblesData = [];
  const categories = {};

  if (actualTenderOffer?.eligiblesSubgroup === eligiblesSubgroupTypes.ALL) {
    switch (actualTenderOffer?.eligiblesType) {
      case tenderEligibleTypes.PARTNERS:
        eligiblesData = parseEligibles(
          partners,
          actualTenderOffer,
          actualSociety,
          'PARTNER'
        );
        break;

      case tenderEligibleTypes.BENEFICIARIES_PHANTOM_SHARES:
        filtered = beneficiaries?.filter(
          (beneficiary) => beneficiary.planType === 'PHANTOM_SHARE'
        );
        filteredWithPlanData = filtered?.map((beneficiary) => ({
          ...beneficiary,
          calculationDate:
            actualTenderOffer?.calculationDate || actualTenderOffer?.startDate,
          planData: beneficiary?.plan
            ? plans.find((plan) => plan._id === beneficiary.plan)
            : null,
        }));
        eligiblesData = parseEligibles(
          filteredWithPlanData,
          actualTenderOffer,
          actualSociety,
          'BENEFICIARY'
        );
        break;

      case tenderEligibleTypes.BENEFICIARIES_STOCK_OPTIONS:
        filtered = beneficiaries.filter(
          (beneficiary) => beneficiary.planType === 'STOCK_OPTION'
        );
        filteredWithPlanData = filtered?.map((beneficiary) => ({
          ...beneficiary,
          calculationDate:
            actualTenderOffer?.calculationDate || actualTenderOffer?.startDate,
          planData: beneficiary?.plan
            ? plans.find((plan) => plan._id === beneficiary.plan)
            : null,
        }));
        eligiblesData = parseEligibles(
          filteredWithPlanData,
          actualTenderOffer,
          actualSociety,
          'BENEFICIARY'
        );
        break;

      case tenderEligibleTypes.ALL_MEMBERS:
        filteredWithPlanData = beneficiaries?.map((member) => ({
          ...member,
          calculationDate:
            actualTenderOffer?.calculationDate || actualTenderOffer?.startDate,
          planData: member?.plan
            ? plans.find((plan) => plan._id === member.plan)
            : null,
        }));
        eligiblesData = parseEligibles(
          partners,
          actualTenderOffer,
          actualSociety,
          'PARTNER'
        ).concat(
          parseEligibles(
            filteredWithPlanData,
            actualTenderOffer,
            actualSociety,
            'BENEFICIARY'
          )
        );
        break;

      default:
        break;
    }
  }

  if (
    actualTenderOffer?.eligiblesSubgroup === eligiblesSubgroupTypes.SOME_MEMBERS
  ) {
    filtered = selectedGroup?.filter((member) =>
      actualTenderOffer?.eligibles.includes(member._id)
    );
    filteredWithPlanData = filtered?.map((member) => ({
      ...member,
      calculationDate:
        actualTenderOffer?.calculationDate || actualTenderOffer?.startDate,
      planData: member?.plan
        ? plans.find((plan) => plan._id === member.plan)
        : null,
    }));
    eligiblesData = parseEligibles(
      filteredWithPlanData,
      actualTenderOffer,
      actualSociety,
      'MIXED'
    );
  }

  if (
    actualTenderOffer?.eligiblesSubgroup === eligiblesSubgroupTypes.SOME_GROUPS
  ) {
    selectedGroup
      .filter((group) => actualTenderOffer?.eligibles.includes(group._id))
      .forEach((group) => {
        const eligibleType = getEligibleType(group);

        if (!categories[eligibleType]) {
          categories[eligibleType] = [group._id];
        } else {
          categories[eligibleType] = [...categories[eligibleType], group._id];
        }
      });

    const elegiblesByPlan = beneficiaries.filter((beneficiary) =>
      categories['PLAN']?.includes(beneficiary.plan)
    );

    const elegiblesByPlanWithPlanData = elegiblesByPlan?.map((beneficiary) => ({
      ...beneficiary,
      calculationDate:
        actualTenderOffer?.calculationDate || actualTenderOffer?.startDate,
      planData: beneficiary?.plan
        ? plans.find((plan) => plan._id === beneficiary.plan)
        : null,
    }));

    const allShares = actualSociety?.shares?.map((share) => ({
      id: share._id,
      classId: share.shareClass?._id || '',
      partnerId: share.partner,
    }));

    const elegiblesByClass = allShares
      ?.map((share) => {
        if (categories['HOLDING_CLASS']?.includes(share.classId)) {
          return partners.find((partner) => partner._id === share.partnerId);
        }
        return null;
      })
      ?.filter((partner) => partner !== null);

    const uniqueElegibles = [
      ...elegiblesByPlanWithPlanData,
      ...elegiblesByClass,
    ].filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i);

    eligiblesData = parseEligibles(
      uniqueElegibles,
      actualTenderOffer,
      actualSociety,
      'MIXED'
    );
  }

  let eligiblesFiltered = eligiblesData;

  if (actualTenderOffer?.maxPercentUnvested) {
    eligiblesFiltered = eligiblesData.filter((eligible) => eligible?.units > 0);
  }

  return eligiblesFiltered;
};

export function getDirectorStatus(director) {
  const currStatusTypes = statusTypes(i18n);
  if (!director.endDate || new Date(director.endDate) - new Date() > 0)
    return currStatusTypes.var.ACTIVE;
  return currStatusTypes.var.INACTIVE;
}

export function getEmployeesEligibles(employees, plan, cifList) {
  let eligibles = [];

  const activeEmployees = employees?.filter(
    (employee) =>
      employee?.terminationDate === null || employee?.terminationDate === ''
  );

  if (plan?.eligibles?.length) {
    const { eligiblesSubgroup } = plan;

    switch (eligiblesSubgroup) {
      case employeesSubgroupTypes.ALL_EMPLOYEES:
        eligibles = activeEmployees;
        break;
      case employeesSubgroupTypes.SOME_GROUPS:
        eligibles = activeEmployees?.filter((employee) =>
          employee?.teams?.some((team) =>
            plan.eligibles.includes(team.externalId.toString())
          )
        );
        break;
      case employeesSubgroupTypes.SOME_EMPLOYEES:
        eligibles = activeEmployees?.filter((employee) =>
          plan.eligibles.includes(employee._id)
        );
        break;
      default:
        break;
    }
  } else {
    const { eligiblesGroup, eligiblesSubgroup } = plan;

    if (eligiblesSubgroup === 'ALL') {
      switch (eligiblesGroup) {
        case employeesGroupTypes.ALL_EMPLOYEES:
          eligibles = activeEmployees;
          break;
        case employeesGroupTypes.EMPLOYEES_WITH_PLANS:
          eligibles = activeEmployees.filter((employee) =>
            cifList.includes(employee.cif)
          );
          break;
        case employeesGroupTypes.EMPLOYEES_WITHOUT_PLANS:
          eligibles = activeEmployees.filter(
            (employee) => !cifList.includes(employee.cif)
          );
          break;
        default:
          break;
      }
    }
  }

  if (plan?.hiringStartDate) {
    eligibles = eligibles.filter((employee) => {
      const employeeStartDate = getEmployeeFirstStartDate(employee);
      return employeeStartDate >= plan.hiringStartDate;
    });
  }

  if (plan?.hiringEndDate) {
    eligibles = eligibles.filter((employee) => {
      const employeeStartDate = getEmployeeFirstStartDate(employee);
      return employeeStartDate <= plan.hiringEndDate;
    });
  }

  if (plan?.hasRequirements) {
    const { salaryMinimum, salaryMaximum } = plan;

    eligibles = eligibles.filter((employee) => {
      const employeeSalary = getEmployeeSalaryAmount(employee);
      return (
        salaryMinimum &&
        employeeSalary >= salaryMinimum &&
        salaryMaximum &&
        employeeSalary <= salaryMaximum
      );
    });
  }

  return eligibles;
}

export const isPartnerActiveFromCif = (cif, society) => {
  if (!cif || !society) return '';
  const partner = society?.partners.find((partner) => partner?.cif === cif);
  const isActive = isPartnerActiveByShares(partner, society);

  return isActive;
};

export const getPartnerCreationDate = (cif, society) => {
  if (!cif || !society) return '';
  const partner = society?.partners.find((partner) => partner?.cif === cif);
  const creationDate = partner?.registerDate || '';

  return creationDate;
};

export const mergeHolders = (first, second) => {
  if (!first || !second) return [];
  const allHolders = [...first, ...second].reduce((acc, currLevel) => {
    currLevel.forEach((society) => {
      if (!acc[society.cif]) {
        acc[society.cif] = society;
      }
    });
    return acc;
  }, {});
  const merge = [];
  const maxLength = first.length > second.length ? first.length : second.length;
  for (let i = 1; i < maxLength; i += 1) {
    const firstCifs =
      i < first.length ? first[i].map((society) => society.cif) : [];
    const secondCifs =
      i < second.length ? second[i].map((society) => society.cif) : [];
    const cifSet = new Set([...firstCifs, ...secondCifs]);
    const cifArr = Array.from(cifSet);
    const currLevelSocieties = cifArr.map((cif) => allHolders[cif]);
    merge.push(currLevelSocieties);
  }
  return merge;
};
