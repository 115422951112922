export default {
  // Society
  CREATE_SOCIETY: 'CREATE_SOCIETY',
  UPDATE_SOCIETY: 'UPDATE_SOCIETY',
  SET_SOCIETY: 'SET_SOCIETY',
  DELETE_SOCIETY: 'DELETE_SOCIETY',
  CLEAN_SOCIETY: 'CLEAN_SOCIETY',

  // Beneficiary Plans
  CREATE_STOCK_PLAN: 'CREATE_STOCK_PLAN',
  GET_STOCK_PLAN: 'GET_STOCK_PLAN',
  GET_STOCK_PLANS: 'GET_STOCK_PLANS',
  CREATE_PHANTOM_PLAN: 'CREATE_PHANTOM_PLAN',
  UPDATE_STOCK_PLAN: 'UPDATE_STOCK_PLAN',
  DELETE_STOCK_PLAN: 'DELETE_STOCK_PLAN',

  // Employees
  CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
  GET_EMPLOYEES: 'GET_EMPLOYEES',
  UPDATE_EMPLOYEE: 'UPDATE_EMPLOYEE',
  DELETE_EMPLOYEE: 'DELETE_EMPLOYEE',

  // Employees Plan
  CREATE_EMPLOYEES_PLAN: 'CREATE_EMPLOYEES_PLAN',
  GET_EMPLOYEES_PLANS: 'GET_EMPLOYEES_PLANS',
  UPDATE_EMPLOYEES_PLAN: 'UPDATE_EMPLOYEES_PLAN',
  DELETE_EMPLOYEES_PLAN: 'DELETE_EMPLOYEES_PLAN',

  // Drafts
  CREATE_DRAFT: 'CREATE_DRAFT',
  GET_DRAFTS: 'GET_DRAFTS',
  UPDATE_DRAFT: 'UPDATE_DRAFT',
  DELETE_DRAFT: 'DELETE_DRAFT',

  // Holding Classes
  CREATE_HOLDING_CLASS: 'CREATE_HOLDING_CLASS',
  GET_HOLDING_CLASSES: 'GET_HOLDING_CLASSES',
  UPDATE_HOLDING_CLASS: 'UPDATE_HOLDING_CLASS',

  // PartnerBook
  SET_PARTNER_BOOK: 'SET_PARTNER_BOOK',

  // Sidenav
  TOGGLE_SIDE_NAV: 'TOGGLE_SIDE_NAV',

  // FullWidth
  TOGGLE_FULL_WIDTH: 'TOGGLE_FULL_WIDTH',
  SET_FULL_WIDTH: 'SET_FULL_WIDTH',

  // Alerts
  ADD_ALERT: 'ADD_ALERT',
  REMOVE_ALERT: 'REMOVE_ALERT',

  // Documents
  ADD_DOCUMENT: 'ADD_DOCUMENT',
  SET_DOCUMENTS: 'SET_DOCUMENTS',
  DELETE_DOCUMENT: 'DELETE_DOCUMENT',
  DELETE_DOCUMENTS: 'DELETE_DOCUMENTS',
  UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',

  // Preview
  SET_PREVIEW: 'SET_PREVIEW',
  UPDATE_PREVIEW: 'UPDATE_PREVIEW',
  DELETE_PREVIEW: 'DELETE_PREVIEW',

  // Modals
  SET_MODAL: 'SET_MODAL',

  // Tender Offers
  GET_TENDER_OFFERS: 'GET_TENDER_OFFERS',
  CREATE_TENDER_OFFER: 'CREATE_TENDER_OFFER',
  UPDATE_TENDER_OFFER: 'UPDATE_TENDER_OFFER',
  DELETE_TENDER_OFFER: 'DELETE_TENDER_OFFER',

  // Tender Offers Shares
  GET_TENDER_OFFERS_SHARES: 'GET_TENDER_OFFERS_SHARES',
  CREATE_TENDER_OFFER_SHARES: 'CREATE_TENDER_OFFER_SHARES',
  UPDATE_TENDER_OFFER_SHARES: 'UPDATE_TENDER_OFFER_SHARES',
  DELETE_TENDER_OFFER_SHARES: 'DELETE_TENDER_OFFER_SHARES',

  // Boards
  GET_BOARD: 'GET_BOARD',
  GET_BOARDS: 'GET_BOARDS',
  CREATE_BOARD: 'CREATE_BOARD',
  UPDATE_BOARD: 'UPDATE_BOARD',
  DELETE_BOARD: 'DELETE_BOARD',

  // Notifications
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',

  // Shares
  UPDATE_SHARE: 'UPDATE_SHARE',

  // Partner Categories
  GET_PARTNER_CATEGORIES: 'GET_PARTNER_CATEGORIES',
  CREATE_PARTNER_CATEGORY: 'CREATE_PARTNER_CATEGORY',
  UPDATE_PARTNER_CATEGORY: 'UPDATE_PARTNER_CATEGORY',
  DELETE_PARTNER_CATEGORY: 'DELETE_PARTNER_CATEGORY',

  // Partner Representants
  GET_PARTNER_REPRESENTANTS: 'GET_PARTNER_REPRESENTANTS',
  CREATE_PARTNER_REPRESENTANT: 'CREATE_PARTNER_REPRESENTANT',
  UPDATE_PARTNER_REPRESENTANT: 'UPDATE_PARTNER_REPRESENTANT',
  DELETE_PARTNER_REPRESENTANT: 'DELETE_PARTNER_REPRESENTANT',

  // Notifications
  GET_GLOBAL_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  CREATE_GLOBAL_NOTIFICATION: 'CREATE_NOTIFICATION',
  UPDATE_GLOBAL_NOTIFICATION: 'UPDATE_NOTIFICATION',
  DELETE_GLOBAL_NOTIFICATION: 'DELETE_NOTIFICATION',

  // Communication
  GET_STOCK_COMMUNICATIONS: 'GET_STOCK_COMMUNICATIONS',
  CREATE_STOCK_COMMUNICATION: 'CREATE_STOCK_COMMUNICATION',
  UPDATE_STOCK_COMMUNICATION: 'UPDATE_STOCK_COMMUNICATION',
  DELETE_STOCK_COMMUNICATION: 'DELETE_STOCK_COMMUNICATION',

  // Guests
  GET_GUESTS: 'GET_GUESTS',
  CREATE_GUEST: 'CREATE_GUEST',
  UPDATE_GUEST: 'UPDATE_GUEST',
  DELETE_GUEST: 'DELETE_GUEST',
};
