import { useEffect, useState } from 'react';

import { Beneficiary, StockPlan } from 'types';
import { getSharesFromPercent } from '../utils/getSharesFromPercent';

type Props = {
  beneficiary: Beneficiary;
  plan: StockPlan;
  totalShares: number;
};

const getPeriods = (beneficiary: Beneficiary, plan: StockPlan) => {
  const beneficiaryPeriods =
    beneficiary?.customConditions?.consolidationPeriods || [];
  const planPeriods = plan?.consolidationPeriods || [];

  return beneficiaryPeriods || planPeriods;
};

export function useGetConsolidationPeriods({
  beneficiary,
  plan,
  totalShares,
}: Props) {
  const [consolidationPeriodsByPercent, setConsolidationPeriodsByPercent] =
    useState<number[]>([]);
  const [consolidationPeriodsByUnits, setConsolidationPeriodsByUnits] =
    useState<number[]>([]);

  useEffect(() => {
    const periodsByPercent: number[] = getPeriods(beneficiary, plan);
    setConsolidationPeriodsByPercent(periodsByPercent);

    const periodsByUnits: number[] = [];
    periodsByPercent.forEach((period) => {
      const value = getSharesFromPercent({
        percent: period,
        totalShares,
      });
      periodsByUnits.push(value);
    });

    setConsolidationPeriodsByUnits(periodsByUnits);
  }, [beneficiary, plan, totalShares]);

  return {
    data: { consolidationPeriodsByPercent, consolidationPeriodsByUnits },
    isLoading: false,
    isError: false,
    actions: {
      setConsolidationPeriodsByPercent,
      setConsolidationPeriodsByUnits,
    },
  };
}
