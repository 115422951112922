import { useTranslate } from 'hooks/useTranslate';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { User } from 'types';

import SocietyAdministratorRow from './SocietyAdministratorRow';

type SocietyAdministratorTableProps = {
  societyAdmins: User[];
};

const SocietyAdministratorTable: FC<SocietyAdministratorTableProps> = ({
  societyAdmins,
}) => {
  const { t } = useTranslate();

  const user = useSelector((state: State) => state.user);
  const isDemo = useSelector((state: State) => state.society?.role?.isDemo);
  const isAdmin = useSelector((state: State) => state.society?.role?.isAdmin);
  const actualSociety = useSelector(
    (state: State) => state?.society?.actualSociety
  );

  const [rows, setRows] = useState<ReactElement[]>([]);

  useEffect(() => {
    if (societyAdmins?.length) {
      setRows(
        societyAdmins?.map((administrator, index) => (
          <SocietyAdministratorRow
            administrator={administrator}
            society={actualSociety}
            user={user}
            isAdmin={isAdmin}
            index={index}
            key={administrator['_id'] || ''}
          />
        ))
      );
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyAdmins]);

  return (
    <div className="card card-bordered card-preview">
      <table
        className="nk-tb-list nk-tb-ulist"
        data-testid="society-administrator-table"
      >
        <thead>
          <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
            <th className="nk-tb-col tb-col-xl">
              <span>#</span>
            </th>

            <th className="nk-tb-col">
              <span>{t('NameAndSurname')}</span>
            </th>

            <th className="nk-tb-col tb-col-xl">
              <span>{t('HighDate')}</span>
            </th>

            <th className="nk-tb-col tb-col-xl">
              <span>{t('LastAccess')}</span>
            </th>

            <th className="nk-tb-col tb-col-xl">
              <span>{t('E-Mail')}</span>
            </th>

            <th className="nk-tb-col">
              <span>{t('AccessLevel')}</span>
            </th>

            {isAdmin && !isDemo && (
              <th className="nk-tb-col nk-tb-col-tools">
                <span />
              </th>
            )}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

export default SocietyAdministratorTable;
